import axios, {AxiosRequestConfig} from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {ChatUserAttributeFilter} from "../model/chatUserAttribute"
import {ChannelUserGroupConfig, ChannelUserSortingRule} from "../model/channelUser";

export type PaginationTarget = 'email' | 'phone' | 'contact' | string;
export type PaginationProps = {
    channelId: string,
    offset: number,
    limit: number,
    filters?: (ChatUserAttributeFilter[])[]
    target?: {[key in PaginationTarget]: boolean}
    sortingRule?: ChannelUserSortingRule
}

export type DownloadProps = {
    filters: (ChatUserAttributeFilter[])[],
    limit: number,
    dateTimezone?: string
    reason: string
    password: string
}

export type UserJobType = 'upload_user'

class ChannelUserRepository {

    async read(channelId: string, clientId: string, masking: boolean) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/${clientId}`, {
            params: {masking}
        });
    }

    async readWithPersonalInfo(channelId: string, name: string, phone: string, excludeId?: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users`, {
            params: {name, phone, excludeId}
        });
    }

    async findJobs(channelId: string, jobType: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/user-jobs`, {
            params: {jobType: jobType}
        });
    }

    async find(props: PaginationProps) {
        const {channelId, ...body} = props;
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/search-users/paginate`, body);
    }

    async download(channelId: string, body: DownloadProps, config?: AxiosRequestConfig) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/download`, body, config);
    }

    async downloadSample(channelId: string, config?: AxiosRequestConfig) {
        return axios.post(
            `${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/sample-download`,
            {},
            config
        );
    }

    async getClientCount(channelId: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/count`);
    }

    async create(channelId: string, channelUser: Object) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users`, channelUser);
    }

    async update(channelId: string, clientId: string, channelUser: Object) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/${clientId}`, channelUser);
    }

    async delete(channelId: string, clientId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/${clientId}`);
    }

    async tagging(channelId: string, clientIds: string[], tag: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/tags`, {
            chatUserIds: clientIds,
            tag
        });
    }

    async exists(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/exists`);
    }

    async upload(channelId: string, file: File, config?: AxiosRequestConfig) {
        let form = new FormData();
        form.append('file', file);
        return axios.post(
            `${CHATBOT_CMS_HOST}/v1/channels/${channelId}/users/upload`,
            form,
            config
        );
    }

    async getUserGroupConfig(channelId: string) {
        return axios.get(
            `${CHATBOT_CMS_HOST}/v1/channels/${channelId}/user-group/config`,
        );
    }

    async updateUserGroupConfig(channelId: string, config: ChannelUserGroupConfig) {
        return axios.put(
            `${CHATBOT_CMS_HOST}/v1/channels/${channelId}/user-group/config`,
            config
        );
    }

}

export default new ChannelUserRepository();
