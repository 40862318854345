import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {ChatUserAttributeFilter} from "../model/chatUserAttribute";

class UserSegmentRepository {

    async find(channelId: string, segmentName?: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/segments`, {
            params: {
                segmentName
            }
        });
    }

    async existed(channelId: string, segmentName: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/segments/existed`, {
            params: {segmentName}
        });
    }

    async create(channelId: string, name: string, clientFilterData: (ChatUserAttributeFilter[])[]) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/segments`, {
            name, clientFilterData
        });
    }

    async update(channelId: string, segmentId: string, name: string, clientFilterData: (ChatUserAttributeFilter[])[]) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/segments/${segmentId}`, {
            name, clientFilterData
        });
    }

    async delete(channelId: string, groupId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/segments/${groupId}`);
    }
}

export default new UserSegmentRepository();
