import {action, computed, makeObservable, observable} from "mobx";
import {SubscriptionPlan} from "../model/subscription";
import {StoreState} from "../model/common";
import subscriptionPlanRepository from "../repository/SubscriptionPlanRepository";


class SubscriptionPlanListStore {
    @observable
    data: {[key: string]: SubscriptionPlan};

    @observable
    state: StoreState;

    constructor() {
        this.data = {};
        this.state = 'none';

        makeObservable(this);
    }

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await subscriptionPlanRepository.find();

            this.data = data.result.reduce((acc: {[key: string]: SubscriptionPlan}, cur: SubscriptionPlan) => {
                acc[cur.type] = cur;
                return acc;
            }, {});
            this.state = 'done';
        } catch (e) {
            this.state = 'pending';
            throw e;
        }
    }

    @action
    clear() {
        this.data = {};
        this.state = 'none';
    }

    @computed
    get subscriptions() {
        return Object.values(this.data);
    }
}

export default SubscriptionPlanListStore;