import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {WelcomeStyle} from "../model/conversationMaker";

class ChatbotTemplateRepository {

    async find(userId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/chatbot-templates?userId=${userId}`);
    }

    async update(channelId: string,
                 template: string,
                 skills: string[],
                 sortedCsSkills: string[],
                 welcomeStyle: WelcomeStyle,
                 deploy?: boolean) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/template`, {
            template, skills, sortedCsSkills, welcomeStyle: welcomeStyle, deploy: deploy
        });
    }
}

export default new ChatbotTemplateRepository();
