import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {LivechatBoardCreate, LiveChatChannel, Sangdamtalk} from "../model/livechat";

class LiveChatRepository {

    async readChannel(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel`);
    }

    async updateChannel(channelId: string, liveChatChannel: LiveChatChannel) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel`, liveChatChannel);
    }

    async patchChannel(channelId: string, body: {[id: string]: any}) {
        return axios.patch(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel`, body);
    }

    async updateSangdamTalk(channelId: string, kakaoConfig: Sangdamtalk) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/kakao`, kakaoConfig);
    }

    async createCategory(channelId: string, name: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/categories`, {name: name});
    }

    async updateCategory(channelId: string, categoryId: string, name: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/categories/${categoryId}`, {name: name});
    }

    async deleteCategory(channelId: string, categoryId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/categories/${categoryId}`);
    }

    async findCategories(channelId: string, name?: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/categories`, {
            params: {name: name}
        });
    }

    async findCategorySummary(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/summary/categories`);
    }

    async findMembers(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/members`);
    }

    async deleteMember(channelId: string, userId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/members`, {
            params: {userId}
        });
    }

    async inviteMember(channelId: string, username: string, admin: boolean) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/member-invite`, {username, admin});
    }

    async findMemberInvitations(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/member-invite`);
    }

    async cancelMemberInvitation(channelId: string, invitationId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/member-invite/${invitationId}`);
    }

    async getAppVersion() {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/livechat-apps/version`);
    }

    async findBoards(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/boards`);
    }

    async createBoard(channelId: string, board: LivechatBoardCreate) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/boards`, board);
    }

    async updateBoard(channelId: string, boardId: string, board: LivechatBoardCreate) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/boards/${boardId}`, board);
    }

    async deleteBoard(channelId: string, boardId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/boards/${boardId}`);
    }

    async readSangdamtalk(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/sangdamtalk`);
    }

    async createSangdamtalk(channelId: string, pfId: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/sangdamtalk/${pfId}`)
    }

    async updateSangdamtalkLinkUrl(channelId: string, linkUrl: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/sangdamtalk/link`, {
            'linkUrl': linkUrl
        })
    }

    async deleteSangdamtalk(channelId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/sangdamtalk`)
    }

    async findManagers(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/managers`);
    }

    async setBannedWordsWithTemplate(channelId: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/livechat/channel/apply-banned-words-template`);
    }

}

export default new LiveChatRepository();