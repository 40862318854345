import {action, computed, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {LiveChatMember} from "../model/livechat";
import liveChatRepository from "../repository/LiveChatRepository";


class LiveChatMemberListStore {
    @observable
    data: LiveChatMember[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }


    @action
    async load(channelId: string) {
        this.state = 'pending';
        try {
            const {data} = await liveChatRepository.findMembers(channelId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }

    @computed
    get isLoading() {
        return this.state === 'pending';
    }

    @computed
    get isError() {
        return this.state === 'error';
    }

    @computed
    get isEmpty() {
        return this.state === 'done' && this.data.length < 1
    }
}

export default LiveChatMemberListStore;