import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {LiveChatMemberInvitation} from "../model/livechat";
import liveChatRepository from "../repository/LiveChatRepository";

class LiveChatMemberInvitationListStore {
    @observable
    data: LiveChatMemberInvitation[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(channelId: string) {
        this.state = 'pending';
        try {
            const {data} = await liveChatRepository.findMemberInvitations(channelId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }
}

export default LiveChatMemberInvitationListStore;