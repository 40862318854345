import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {EditorElement, FileInfo} from "@42maru/content-editor";

class AdditionFAQRepository {

    async findFAQChannel(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq`);
    }

    // async insertFAQChannel(channelId: string) {
    //     return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq`);
    // }
    //
    // async updateFAQChannel(channelId: string, threshold: number, checked: boolean) {
    //     return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq`,
    //         {threshold, checked}
    //     );
    // }

    async findFAQCategories(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/category`);
    }

    async insertFAQCategory(channelId: string, data: string){
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/category`, {
            data
        });
    }

    async updateFAQCategory(channelId: string, categoryId: string, categoryData: string){
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/category`, {
            categoryId, data: categoryData
        });
    }

    async deleteFAQCategory(channelId: string, categoryId: string){
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/category`, {
            params: {category_id: categoryId}
        });
    }

    async findFAQTables(channelId: string, categoryId: string){
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/table`, {
            params: {category_id: categoryId}
        });
    }

    async updateFAQTable(channelId: string, answerId: string, answerData: string, answerDataOrigin: EditorElement[],
                         questionId: string, questionData: string, questionDataOrigin: EditorElement[], answerFiles?: FileInfo[]){
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/table`, {
            answer_id: answerId,
            answer_data: answerData,
            answer_data_origin: answerDataOrigin,
            answer_files: answerFiles,
            question_id: questionId,
            question_data: questionData,
            question_data_origin: questionDataOrigin,
        });
    }

    async insertFAQTable(channelId: string, categoryId: string, categoryData: string,
                         answerData: string, answerDataOrigin: EditorElement[],
                         questionData: string, questionDataOrigin: EditorElement[], answerFiles?: FileInfo[]){
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/table`, {
            category_id: categoryId,
            category_data: categoryData,
            answer_data: answerData,
            answer_data_origin: answerDataOrigin,
            answer_files: answerFiles,
            question_data: questionData,
            question_data_origin: questionDataOrigin,
        });
    }

    async deleteFAQTable(channelId: string, answerId: string, questionId: string){
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/table`, {
            params: {
                answer_id: answerId,
                question_id: questionId
            }
        });
    }

    async insertFAQFile(channelId: string, file: any) {
        let form = new FormData();
        form.append("file", file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/bulk`, form
        );
    }

    async checkFAQJob(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/faq/job`);
    }

}

export default new AdditionFAQRepository();
