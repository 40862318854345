import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class SubscriptionRepository {

    async read(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/subscription`);
    }

    async change(channelId: string, plan: string, additions: string[], paymentCycle: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/subscription`, {
            plan,
            additions,
            paymentCycle
        });
    }

    async changeAdditions(channelId: string, additions: string[], paymentCycle: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/subscription/additions`, {
            additions,
            paymentCycle
        });
    }

    async cancel(channelId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/subscription`);
    }
}


export default new SubscriptionRepository();