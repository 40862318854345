import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class PaymentTransactionRepository {
    async find(channelId: string, params: {method?: string, type?: 'payment'|'cancel', offset?: number, limit?: number}) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/payment-transactions`, {
            params: params
        });
    }
}

export default new PaymentTransactionRepository();