import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {AdditionFAQCategory} from "../model/setting"


class AdditionFAQCategoryStore {
    @observable
    data?: AdditionFAQCategory;

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        this.data = undefined;
        makeObservable(this);
    }

    @action
    async load(category: AdditionFAQCategory) {
        this.state = 'pending';
        try {
            this.data = category;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default AdditionFAQCategoryStore;