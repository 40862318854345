import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {CommerceAgencyProduct} from "../model/commerceProduct"

export type CommerceProductFindProp = {
    channelId: string,
    page: number,
    size: number,
    name?: string,
    productInfo: {
        agencyId?: string
        name?: string
    }
}

class CommerceProductRepository {

    async createFromAgencyProduct(channelId: string, products: CommerceAgencyProduct[]) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/templates/commerce/channels/${channelId}/products`, {
            products,
            createFrom: 'agency'
        });
    }
    async find(props: CommerceProductFindProp) {
        const {channelId, ...body} = props;
        return axios.post(`${CHATBOT_CMS_HOST}/v1/templates/commerce/channels/${channelId}/products/fetch`, body);
    }

    async updateRestockDate(channelId: string, productId: string, restockDate?: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/templates/commerce/channels/${channelId}/products/${productId}/restock-date`, {
            restockDate
        });
    }

    async delete(channelId: string, productId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/templates/commerce/channels/${channelId}/products/${productId}`);
    }
}

export default new CommerceProductRepository();