import React from "react";
import I18nStore from "./I18nStore";
import ChannelListStore from "./ChannelListStore";
import AdditionFAQChannelStore from "./AdditionFAQChannelStore";
import LiveChatCategorySummaryStore from "./LiveChatCategorySummaryStore";
import LiveChatMemberListStore from "./LiveChatMemberListStore";
import LiveChatMemberInvitationListStore from "./LiveChatMemberInvitationListStore";
import UserSegmentStore from "./UserSegmentStore"
import UserStore from "./UserStore"
import MessageListStore from "./MessageListStore"
import ChatbotTemplatesStore from "./ChatbotTemplatesStore"
import MessagingUserStore from "./MessaginguserStore";
import ConversationFlowStore from "./ConversationFlowStore";
import CommerceProductStore from "./CommerceProductStore";
import SubscriptionStore from "./SubscriptionStore";
import SubscriptionPlanListStore from "./SubscriptionPlanListStore";
import SubscriptionBillingListStore from "./SubscriptionBillingListStore";
import PaymentAccountStore from "./PaymentAccountStore";
import PaymentAccountTxListStore from "./PaymentAccountTxListStore";
import PaymentCardTxListStore from "./PaymentCardTxListStore";
import ConnectorAccountStore from "./ConnectorAccountStore";
import AdditionFAQCategoryStore from "./AdditionFAQCategoryStore";
import AdditionFAQCategorySelectStore from "./AdditionFAQCategorySelectStore";
import AdditionFAQTableStore from "./AdditionFAQTableStore";
import MessageUserListStore from "./MessageUserListStore";
import UserJogStore from "./UserJogStore";
import LiveChatBoardListStore from "./LiveChatBoardListStore";
import ConnectorCafe24BoardListStore from "./ConnectorCafe24BoardListStore";
import AdditionFAQJobStore from "./AdditionFAQJobStore";

export const storeContext = React.createContext({
    i18nStore: new I18nStore(),
    channelListStore: new ChannelListStore(),
    additionFAQCategoryStore: new AdditionFAQCategoryStore(),
    additionFAQCategorySelectStore: new AdditionFAQCategorySelectStore(),
    additionFAQTableStore: new AdditionFAQTableStore(),
    additionFAQChannelStore: new AdditionFAQChannelStore(),
    additionFAQJobStore: new AdditionFAQJobStore(),
    liveChatCategorySummaryStore: new LiveChatCategorySummaryStore(),
    liveChatMemberListStore: new LiveChatMemberListStore(),
    liveChatMemberInvitationListStore: new LiveChatMemberInvitationListStore(),
    liveChatBoardListStore: new LiveChatBoardListStore(),
    userStore: new UserStore(),
    userSegmentStore: new UserSegmentStore(),
    messageListStore: new MessageListStore(),
    chatbotTemplatesStore: new ChatbotTemplatesStore(),
    messagingUserStore: new MessagingUserStore(),
    conversationFlowStore: new ConversationFlowStore(),
    commerceProductStore: new CommerceProductStore(),
    subscriptionPlanListStore: new SubscriptionPlanListStore(),
    subscriptionStore: new SubscriptionStore(),
    subscriptionBillingListStore: new SubscriptionBillingListStore(),
    paymentAccountStore: new PaymentAccountStore(),
    paymentAccountTxListStore: new PaymentAccountTxListStore(),
    paymentCardTxListStore: new PaymentCardTxListStore(),
    connectorAccountStore: new ConnectorAccountStore(),
    messageUserListStore: new MessageUserListStore(),
    userJogStore: new UserJogStore(),
    connectorCafe24BoardListStore: new ConnectorCafe24BoardListStore(),
    // chatbotSkillSettingStore: new ChatbotSkillSettingStore(),
});
