import {action, makeObservable, observable, toJS} from "mobx";

import {StoreState} from "../model/common";
import {MessageGroupUnion, MessageParams, MessageStatus} from "../model/message";
import messageRepository from "../repository/MessageRepository";

const DEFAULT_MESSAGES_SIZE = 20;

class MessageListStore {
    @observable
    data: MessageGroupUnion[];

    @observable
    state: StoreState;

    @observable
    nextState: StoreState;

    @observable
    query: {offset: number, limit: number, status?: MessageStatus};

    @observable
    hasNextMessage: boolean;

    constructor() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        this.query = {offset: 0, limit: DEFAULT_MESSAGES_SIZE};
        this.hasNextMessage = false;
        makeObservable(this)
    }

    @action
    async load(channelId: string,
               params: MessageParams) {
        this.state = 'pending';
        this.query = {
            ...params,
            offset: params.offset || 0,
            limit: params.limit || DEFAULT_MESSAGES_SIZE,
        };
        try {
            const {data} = await messageRepository.find(channelId, toJS(this.query));
            this.data = data.result;
            this.hasNextMessage = this.data.length === this.query.limit;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async next(channelId: string) {
        this.nextState = 'pending';
        this.query = {
            ...this.query,
            offset: this.query.offset + this.query.limit
        };
        try {
            const {data} = await messageRepository.find(channelId, toJS(this.query));
            this.data = [...this.data, ...data.result];
            this.hasNextMessage = data.result.length === this.query.limit;
            this.nextState = 'done';
        } catch (e) {
            this.nextState = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        this.query = {offset: 0, limit: DEFAULT_MESSAGES_SIZE};
        this.hasNextMessage = false;
    }
}

export default MessageListStore