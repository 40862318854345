import React, {Suspense, useMemo} from "react";
import {IntlProvider, useIntl} from "react-intl"
import styled from "styled-components";
import {Route, Switch} from "react-router-dom";

import useStores from "./hook/useStores";
import Dialog from "./hook/useDialog"
import {DictType} from "./model/common";
import ko from "./i18n/ko";
import en from "./i18n/en";
import * as views from "./view";
import "moment/locale/ko";
import {NotificationContainer} from "./hook/useNotification";
import PrivateRoute from "./component/PrivateRoute";
import ReactTooltip from "react-tooltip";
import SwingChatQnaChatbot from "./component/SwingChatQnaChatbot";
import AuthProvider from "./component/authentication/AuthProvider";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./component/ErrorFallback";
import View40X from "./component/error/View40X";

import 'simplebar/dist/simplebar.min.css';
import FullAreaSpinner from "./component/FullAreaSpinner";
import {Toaster} from "react-hot-toast";

const AppWrapper = () => {
    const {i18nStore} = useStores();
    const messages: DictType<Record<string, string>> = useMemo(() => {
        return {"ko": ko, "en": en};
    }, []);

    return (
        <IntlProvider locale={i18nStore.language} messages={messages[i18nStore.language]}>
            <App/>
        </IntlProvider>
    )
};

const App: React.FC = () => {
    const intl = useIntl();
    return (
        <AuthProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AppStyle>
                    <Switch>
                        <PrivateRoute exact path={'/'}>
                            <views.channelListView />
                        </PrivateRoute>
                        <Route exact path={'/auth/login'}>
                            <views.loginView />
                        </Route>
                        <Route exact path={'/sso/login'}>
                            <views.ssoLoginView />
                        </Route>
                        <Route exact path={'/auth/signup'}>
                            <views.signUpView />
                        </Route>
                        <Route exact path={'/auth/signup-complete'}>
                            <views.signUpResultView />
                        </Route>
                        <Route exact path={'/auth/confirm-email'}>
                            <views.confirmEmailView />
                        </Route>
                        <Route exact path={'/auth/forgot-password'}>
                            <views.forgotPasswordView />
                        </Route>
                        <Route exact path={'/auth/reset-password'}>
                            <views.resetPasswordView />
                        </Route>
                        <Route exact path={'/auth/app-login'}>
                            <views.appLoginRedirectView />
                        </Route>
                        <Route exact path={'/auth/app-logout'}>
                            <views.appLogoutConfirmView />
                        </Route>
                        <Route exact path={'/auth/mfa'}>
                            <views.mfaAuthenticationView />
                        </Route>
                        <PrivateRoute exact path={'/auth/account'}>
                            <Suspense fallback={<FullAreaSpinner />}>
                                <views.accountView />
                            </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path={'/auth/account/mfa'}>
                            <Suspense fallback={<FullAreaSpinner />}>
                                <views.mfaFactorView />
                            </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path={'/auth/authentication/email'}>
                            <Suspense fallback={<></>}>
                                <views.authenticationEmail />
                            </Suspense>
                        </PrivateRoute>
                        <PrivateRoute exact path={'/auth/stale-password'}>
                            <views.StalePasswordChangeView />
                        </PrivateRoute>
                        <PrivateRoute exact path={'/auth/invite'}>
                            <views.inviteAgreeView />
                        </PrivateRoute>
                        <PrivateRoute path={'/channels/:channelId'}>
                            <Suspense fallback={<></>}>
                                <views.channelContextView />
                            </Suspense>
                        </PrivateRoute>
                        <Route path={'*'}>
                            <View40X code="404" title="PAGE NOT FOUND" description={intl.formatMessage({id: 'i00003'})} />
                        </Route>
                    </Switch>
                </AppStyle>
            </ErrorBoundary>
            <NotificationContainer/>
            <Dialog/>
            <Toaster position="top-right"
                     containerStyle={{top: "14px"}}
            />
            <SwingChatQnaChatbot />
            <ReactTooltip id="global-tooltip" effect="solid" />
        </AuthProvider>
    );

};

const AppStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export default AppWrapper
