import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {MessageForm, MessageParams} from "../model/message";
import queryString from "query-string";


class MessageRepository {

    async create(channelId: string, message: MessageForm) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages`, message);
    }

    async post(channelId: string, messageId: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}`);
    }

    async find(channelId: string, params: MessageParams) {
        return axios.get(`${CHATBOT_CMS_HOST}/v2/channels/${channelId}/messages`, {
            params: params
        });
    }


    async get_total_amount(channelId: string, params: MessageParams) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/amount`, {
            params: params,
            paramsSerializer: params => {
                return queryString.stringify(params);
            }
        });
    }


    async read(channelId: string, messageId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}`);
    }

    async update(channelId: string, messageId: string, message: MessageForm) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}`, message);
    }

    async cancelReservation(channelId: string, messageId: string) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}/status/cancel`);
    }

    async delete(channelId: string, messageId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}`);
    }

    async findMessageUsers(channelId: string, messageId: string, offset?: number, limit?: number) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/${messageId}/users`, {
            params: {
                offset: offset || 0,
                limit: limit || 20
            }
        });
    }

    async upload(channelId: string, file: File) {
        let form = new FormData();
        form.append('file', file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messages/-/files`, form);
    }

}

export default new MessageRepository();
