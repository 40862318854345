import {action, makeAutoObservable, observable} from "mobx";

import {StoreState} from "../model/common";
import {emptyPage, Page} from "../model/pagination";
import {CommerceAgencyProduct, CommerceProduct} from "../model/commerceProduct";
import commerceProductRepository, {CommerceProductFindProp} from "../repository/CommerceProductRepository";
import commerceAgencyProductRepository from "../repository/CommerceAgencyProductRepository";


class CommerceProductStore {
    @observable
    products: Page<CommerceProduct>;

    @observable
    agencyProducts: Page<CommerceAgencyProduct>;

    @observable
    state: StoreState;

    @observable
    agencyState: StoreState;

    constructor() {
        this.products = emptyPage();
        this.agencyProducts = emptyPage();
        this.state = 'none';
        this.agencyState = 'none'
        makeAutoObservable(this)
    }

    @action
    async find(props: CommerceProductFindProp) {
        this.state = 'pending';
        try {
            const {data} = await commerceProductRepository.find({...props});
            this.products = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e
        }
    }

    @action
    async findAgency(props: CommerceProductFindProp) {
        this.agencyState = 'pending';
        try {

            const {data} = await commerceAgencyProductRepository.findAgencyProducts({...props});
            this.agencyProducts = data.result;
            this.agencyState = 'done';
        } catch (e) {
            this.agencyState = 'error';
            throw e
        }
    }

    @action
    clearAgency = () => {
        this.agencyProducts = emptyPage()
        this.agencyState = 'none'
    }

    @action
    clear() {
        this.state = 'none';
        this.agencyState = 'none'
        this.agencyProducts = emptyPage()
        this.products = emptyPage()
    }
}

export default CommerceProductStore