import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import chatbotTemplateRepository from "../repository/ChatbotTemplateRepository";
import {ChatbotTemplate} from "../model/setting";

class ChatbotTemplatesStore {
    @observable
    data: ChatbotTemplate[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(userId: string) {
        this.state = 'pending';
        try {
            const {data} = await chatbotTemplateRepository.find(userId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = []
    }
}

export default ChatbotTemplatesStore;
