import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {ChannelCreate, ChannelUpdate} from "../model/channel"
import {ChatUserAttribute} from "../model/chatUserAttribute"

class ChannelRepository {

    async read(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}`);
    }

    async existed(channelName: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/existed`, {
            params: {channelName}
        });
    }

    async find(offset = 0, size = 20, channelName?: String) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels`, {params: {offset, size, channelName}});
    }

    async create(channelCreate: ChannelCreate) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels`, channelCreate);
    }

    async update(channelId: string, channelUpdate: ChannelUpdate) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}`, channelUpdate);
    }

    async findUserAttrMaskingTemplates(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/attribute-masking-templates`);
    }

    async findUserAttributes(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/user-attributes`);
    }

    async updateUserAttributes(channelId: string, attributes: ChatUserAttribute[]) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/user-attributes`, attributes);
    }

    async uploadAvatar(channelId: string, file: File) {
        let form = new FormData();
        form.append('file', file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/avatar`, form);
    }

    async delete(channelId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}`);
    }
}

export default new ChannelRepository();
