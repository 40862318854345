import {atom} from "recoil";
import {Profile} from "../../model/user";


const profileState = atom<Profile|undefined>({
    key: 'profile',
    default: undefined
});

export default profileState;
