import axios from "axios";
import { CHATBOT_CMS_HOST } from "../core/variables";
import moment from "moment";

class ConversationMakerRepository {
    async readConversations(channelId: string, page: number = 0, size: number = 20, condition: string = "",
                            hasSceneName: boolean = false, mode: string = "", senderType: string = "") {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/conversations`,
            {
                params: {
                    'condition': condition,
                    'page': page,
                    'size': size,
                    'has_scene_name': hasSceneName,
                    'mode': mode,
                    'sender_type': senderType
                }
            });
    }

    async readMessagingUsers(channelId: string, page: number = 0, size: number = 20, condition: string = "") {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messaging-users`, { params: { condition, page, size } });
    }

    async readOnlineMessagingUsers(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messaging-users/online`);
    }

    async readMessages(channelId: string, messagingUserId: string, page: number = 0, size: number = 20) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messaging-users/${messagingUserId}/messages`, {
            params: { page, size },
        });
    }

    async readMessagesByConversationId(channelId: string, conversationId: string, page: number = 0, size: number = 20) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/conversations/${conversationId}/messages`, {
            params: { page, size },
        });
    }

    async readMessagesSinceByConversationId(channelId: string, conversationId: string, since: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/conversations/${conversationId}/messages/since`, {
            params: { since_date_time: since },
        });
    }

    async readMessagesSince(channelId: string, messagingUserId: string, since: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messaging-users/${messagingUserId}/messages/since`, {
            params: { since_date_time: since },
        });
    }

    async readScenario(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/scenario`);
    }

    async getConversationFlow(channelId: string, start_date: Date, end_date: Date) {
        const start = moment(start_date).utc().format("YYYY-MM-DD HH:mm:ss");
        const end = moment(end_date).utc().format("YYYY-MM-DD HH:mm:ss");

        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/analytics/chatbot/chat/flow`, {
            params: {
                startDate: start,
                endDate: end,
            },
        });
    }

    async getQueryRankings(channelId: string, start_date: Date, end_date: Date) {
        const start = moment(start_date).utc().format("YYYY-MM-DD HH:mm:ss");
        const end = moment(end_date).utc().format("YYYY-MM-DD HH:mm:ss");

        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/analytics/chatbot/query-rankings`, {
            params: {
                startDate: start,
                endDate: end,
            },
        });
    }

    async getSceneRankings(channelId: string, start_date: Date, end_date: Date) {
        const start = moment(start_date).utc().format("YYYY-MM-DD HH:mm:ss");
        const end = moment(end_date).utc().format("YYYY-MM-DD HH:mm:ss");

        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/analytics/chatbot/scene-rankings`, {
            params: {
                startDate: start,
                endDate: end,
            },
        });
    }

    async updateKakaoTalk (channelId: string, data: any) {
        return axios
            .put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/kakaotalk`, { referer: window.location.href, ...data })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };
    
    async updateNaverTalk (channelId: string, data: any) {
        return axios
            .put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/navertalk`, { referer: window.location.href, ...data })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };
    
    async checkFacebook (channelId: string, facebookPageId: string) {
        return axios
            .get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/facebook`, {
                params: {
                    facebook_page_id: facebookPageId
                }
            })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };
    
    async updateFacebook (channelId: string, data: any) {
        return axios
            .put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/facebook`, { referer: window.location.href, ...data })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };
    
    async checkInstagram (channelId: string, instagramBusinessAccountId: string) {
        return axios
            .get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/instagram`, {
                params: {
                    facebook_page_id: instagramBusinessAccountId
                }
            })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };
    
    async updateInstagram (channelId: string, data: any) {
        return axios
            .put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/instagram`, { referer: window.location.href, ...data })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    };

    async updateLine(channelId: string, data: any){
        return axios
            .put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/messenger/line`, { referer: window.location.href, ...data })
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error));
    }
}

export default new ConversationMakerRepository();
