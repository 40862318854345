const language: Record<string, string> = {
    e00001: "일시적인 오류로 처리할 수 없습니다.\n잠시후 다시 시도해 주세요.",
    // 상담톡 상태 변경 API 에러 메시지
    e10001: '성공',
    e10002: '인증 실패',
    e10003: '토큰 권한 없음',
    e10004: '서비스 점검',
    e10005: '알려지지 않은 에러',
    e10006: '잘못된 인증정보',
    e10007: '차단된 계정',
    e10008: '플러스친구 ID 없음',
    e10009: '플러스친구 ID형식 오류',
    e10010: '중복된 플러스 친구 ID',
    e10011: '고객 요청 번호 없음',
    e10012: '수신도메인 없음',
    e10013: '수신도메인 형식 오류',
    e10014: '플러스친구 ID 불일치',
    e10015: '상태변경 타입 형식 오류',
    e10016: '상태변경 타입 없음',
    e10017: '상태변경 예약 시간 형식 오류',
    e10018: '상태변경 요청 시간 초과',
    e10019: '상태변경 예약 시간 범위 초과',
    e10020: '상태변경 예약 시간 없음',
    e10021: '플러스친구 ID 없음',
    e10022: '플러스친구 ID 불일치',
    e10023: 'API KEY 없음',
    e10024: 'API KEY 불일치',
    e10025: '발급 한도 초과',
    e10026: '삭제 한도 초과',
    // 상담톡 활성화 API 에러 메시지
    e10101: "요청 성공",
    e10102: "권한 없음",
    e10103: "파라미터 오류",
    e10104: "유효하지 않은 발신 프로필",
    e10105: "요청한 데이터가 없음",
    e10106: "요청을 처리할수 있는 상태가 아님",
    e10107: "유효하지 않은 버튼 형식",
    e10108: "비즈니스 인증이 필요한 카카오톡 채널",
    e10109: "상담톡 프로필이 존재하지 않음",
    e10110: "상담톡 계약이 없음",
    e10111: "프로필 정보를 찾을 수 없음",
    e10112: "유효하지 않은 시스템 메시지 내용",
    e10113: "발신프로필 등록이 차단된 상태",
    e10114: "발신프로필 등록이 차단된 상태",
    e10115: "발신프로필 등록이 차단된 상태",
    e10116: "발신프로필 등록이 차단된 상태",
    e10117: "발신프로필 등록이 차단된 상태",
    e10118: "다른 파트너에 의해 이미 상담톡을 이용중인 프로필",
    e10119: "알 수 없는 에러",

    e20001: "로그인이 만료되었습니다. 다시 로그인해주세요.",

    p001: "챗봇",
    p002: "채팅상담",
    p003: "고객관리",
    p004: "메시지",
    p005: "통계",
    p006: "구독 및 결제",
    p007: "챗봇",
    p008: "채팅상담",
    p009: "고객관리",
    p010: "메시지",
    p011: "연동 관리",
    p012: "채널 기본 정보",
    p013: "멤버 권한 관리",

    pc01: "서비스 설정",

    i00001: "챗봇",
    i00002: "방문하시려는 페이지는 권한이 있는 사용자만 사용할 수 있습니다.\n채널 관리자에게 권한을 요청하시거나 권한이 있어도 같은 오류가 발생한다면 고객센터에 문의하여 주시기 바랍니다.",
    i00003: "방문하시려는 페이지의 주소가 잘못 입력되었거나, 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.\n입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.",
    i00004: "챗봇 전체 대화",
    i00005: "챗봇 대화 흐름",
    i00006: "채팅상담 메신저 설정",
    i00007: "메시지 설정",
    i00008: "고객이 상담 연결 시도 시 챗봇이 고객에게 응답할 메시지를 설정합니다.",
    i00009: "상담 요청 메시지",
    i00010: "상담 요청 취소 메시지",
    i00011: "부재중 안내 메시지",
    i00012: "상담 시작 메시지",
    i00013: "상담 종료 메시지",
    i00014: "채팅상담 설정 정보 조회 중 오류가 발생했습니다.",
    i00015: "채팅상담 챗봇 메시지를 저장 했습니다.",
    i00016: "채팅상담 챗봇 메시지 저장 중 오류가 발생했습니다.",
    i00017: "상담 운영시간 설정",
    i00018: "요일별로 상담 운영시간을 설정해 보세요.\n수동으로도 상담 운영 상태를 설정할 수 있습니다.",
    i00019: "상담 운영시간을 저장했습니다.",
    i00020: "상담 운영시간 저장 중 오류가 발생했습니다.",
    i00021: "일",
    i00022: "월",
    i00023: "화",
    i00024: "수",
    i00025: "목",
    i00026: "금",
    i00027: "토",
    i00028: "상담 운영시간 설정 방법",
    i00029: "수동으로 설정",
    i00030: "요일별 시간 설정",
    i00031: "운영 상태",
    i00032: "운영중",
    i00033: "부재중",
    i00034: "요일",
    i00035: "시간",
    i00036: "추가",
    i00037: "시간대",
    i00038: "카테고리 관리",
    i00039: "상담 카테고리를 설정해서 카테고리 별로 상담을 분류하고 어떤 종류의 상담이 많은지 확인할 수 있습니다.\n상담 분배 설정에서 카테고리 별로 상담원을 배치해 상담을 분배 할 수 있습니다.",
    i00040: "카테고리",
    i00041: "상담수",
    i00042: "상담사 수",
    i00043: "생성일",
    i00044: "등록된 카테고리가 없습니다.",
    i00045: "카테고리 등록 중 오류가 발생했습니다.",
    i00046: "카테고리 만들기",
    i00047: "카테고리명",
    i00048: "이미 존재하는 카테고리입니다.",
    i00049: "카테고리를 삭제 하시겠습니까?",
    i00050: "해당 카테고리가 적용된 모든 상담챗에 변경사항이 적용됩니다.",
    i00051: "카테고리 삭제 중 오류가 발생했습니다.",
    i00052: "카테고리 조회 중 오류가 발생했습니다.",
    i00053: "카테고리 수정 중 오류가 발생했습니다.",
    i00054: "카테고리 수정하기",
    i00055: "상담 분배 방식 설정",
    i00056: "순차 및 카테고리 우선 분배 방식으로 상담을 분배 할 수 있습니다.\n카테고리 우선 분배방식은 고객이 상담 요청시 설정한 카테고리를 담당하고 있는 상담사에게 우선 배정됩니다.\n해당 카테고리를 담당하고 있는 상담사의 진행, 대기중인 상담이 카테고리 우선 분배시 상담 제한 수 보다 많을 경우에는 원활한 상담을 위해 순차 분배 방식으로 다른 상담사에게 배정됩니다.",
    i00057: "상담 분배 방식 수정 중 오류가 발생했습니다.",
    i00058: "상담 분배 방식을 수정했습니다.",
    i00059: "분배 방식",
    i00060: "순차 분배",
    i00061: "카테고리 우선 분배",
    i00062: "수동 분배",
    i00063: "카테고리 우선 분배시 상담 제한 수",
    i00064: "금칙어 관리",
    i00065: "금칙어를 등록해서 욕설, 선정적인 발언, 비하 발언 등 비속어를 마스킹 처리할 수 있습니다.",
    i00066: "금칙어 등록 중 오류가 발생했습니다.",
    i00067: "금칙어 삭제 중 오류가 발생했습니다.",
    i00068: "금칙어를 삭제 하시겠습니까?",
    i00069: "금칙어를 삭제 하면 기존 메시지의 마스킹이 모두 제거 됩니다.",
    i00070: "금칙어",
    i00071: "등록된 금칙어가 없습니다.",
    i00072: "더 많은 설정",
    i00073: "채팅상담 메신저는 웹브라우저보다 편리한 별도 앱으로 준비 했습니다.\n앱을 다운로드 하신 후 다양한 설정을 통해 효율적인 상담을 진행해 보세요. ",
    i00074: "채팅상담 앱 실행하기",
    i00075: "채널 설정",
    i00076: "기본 정보",
    i00077: "멤버 관리",
    i00078: "채널을 함께 운영할 새로운 멤버를 초대해 보세요.",
    i00079: "채널 기본정보를 관리 하세요.",
    i00080: "멤버 조회 중 오류가 발생했습니다.",
    i00081: "멤버 삭제",
    i00082: "멤버를 삭제하시겠습니까? 삭제한 멤버는 채널에 접근 할 수 없습니다.",
    i00083: "멤버 삭제 중 오류가 발생했습니다.",
    i00084: "삭제",
    i00085: "멤버",
    i00086: "소유자",
    i00087: "채팅상담 멤버 등록 중 오류가 발생했습니다.",
    i00088: "멤버 초대",
    i00089: "이메일",
    i00090: "유형",
    i00091: "이름",
    i00092: "등록된 멤버가 없습니다.",
    i00093: "오류가 발생했습니다. 다시 불러오기",
    i00094: "멤버 초대",
    i00095: "챗봇 통계",
    i00096: "채팅상담 통계",
    i00097: "고객이 챗봇을 어떻게 사용하는지 확인 할 수 있습니다.",
    i00098: "상담 업무가 어떻게 운영되고 있는지 확인 할 수 있습니다.",
    i00099: "통계",
    i00100: "사진 업로드",
    i00101: "업로드",
    i00102: "채널 로고 등록 중 오류가 발생했습니다.",
    i00103: "채널 주소",
    i00104: "홈페이지",
    i00105: "국가",
    i00106: "시간대",
    i00107: "전화번호",
    i00108: "올바르지 않은 전화번호 입니다.",
    i00109: "채널 프로필 정보 저장 중 오류가 발생했습니다.",
    i00110: "채널 프로필 정보가 수정되었습니다.",
    i00111: "채널 로고가 수정되었습니다.",
    i00112: "채팅상담",
    i00113: "현재 채팅상담 메신저는 전용 어플리케이션에서만 지원합니다. 웹브라우저에서의 지원은 준비중이오니 조금만 기다려주시기 바랍니다.",
    i00114: "채팅상담 앱 실행하기",
    i00115: "윈도우용 프로그램 다운로드",
    i00116: "맥용 프로그램 다운로드",
    i00117: "이메일",
    i00118: "비밀번호",
    i00119: "로그인",
    i00120: "이메일을 입력하세요.",
    i00121: "비밀번호를 입력하세요.",
    i00122: "비밀번호를 잊어버리셨나요?",
    i00123: "회원가입",
    i00124: "아직 회원이 아니신가요?",
    i00125: "이메일을 입력하세요",
    i00126: "비밀번호를 입력하세요",
    i00127: "로그인 상태 유지",
    i00128: "로그인을 위해 계정의 이메일과 비밀번호를 입력하세요.",
    i00129: "이메일 또는 비밀번호가 일치하지 않습니다.",
    i00130: "무료 회원가입",
    i00131: "아직 계정이 없으신가요? 잠깐이면 계정을 생성 할 수 있습니다.",
    i00132: "이름",
    i00133: "성",
    i00134: "이름을 입력하세요",
    i00135: "성을 입력하세요.",
    i00136: "이름을 입력하세요.",
    i00137: "성을 입력하세요.",
    i00138: "이미 계정이 있으신가요?",
    i00139: "서비스 이용약관 (필수)",
    i00140: "개인정보 처리 방침 (필수)",
    i00141: "마케팅 메시지 수신 동의 (선택사항)",
    i00142: "동의",
    i00143: "전체 동의",
    i00144: "닫기",
    i00145: "서비스 이용약관",
    i00146: "개인정보 처리 방침",
    i00147: "마케팅 수신 동의 방침",
    i00148: "이용약관을 확인하고 동의해주세요.",
    i00149: "개인정보 처리방침을 확인하고 동의해주세요.",
    i00150: "회원가입 도중 오류가 발생했습니다.",
    i00151: "이미 가입된 이메일입니다.",
    i00152: "이메일을 확인해주세요.",
    i00153: "가입하신 이메일 {email}로 인증메일을 보냈습니다. 계정 확인을 위해서 메일에 있는 링크를 클릭하여 회원가입을 완료해 주시기 바랍니다.",
    i00154: "만료 또는 변조된 인증정보로 로그인을 시도했습니다.",
    i00155: "비밀번호 초기화",
    i00156: "회원가입시 기입했던 이메일을 입력하시기 바랍니다. 해당 이메일로 비밀번호를 설정할 수 있는 링크를 보내드립니다.",
    i00157: "인증 이메일을 보냈습니다.",
    i00158: "이미 인증받은 계정입니다.",
    i00159: "가입되지 않은 계정입니다.",
    i00160: "비밀번호 초기화 이메일을 보냈습니다.",
    i00161: "이메일 전송중 오류가 발생했습니다.",
    i00162: "새 비밀번호",
    i00163: "새 비밀번호를 입력하세요",
    i00164: "비밀번호 변경",
    i00165: "비밀번호 변경 정보가 잘못 되었거나 만료되었습니다.",
    i00166: "비밀번호 변경중 오류가 발생했습니다.",
    i00167: "새 비밀번호를 입력해서 변경해 주세요",
    i00168: "비밀번호 변경 완료",
    i00169: "로그인 버튼을 눌러 새로운 비밀번호로 로그인해주세요",
    i00170: "혹시 이메일을 받지 못하셨나요?",
    i00171: "애플리케이션 로그인",
    i00172: "{user} 계정으로 로그인 되었습니다. 애플리케이션을 실행하는 동안 잠시만 기다려주세요.",
    i00173: "애플리케이션 로그아웃",
    i00174: "애플리케이션에서 로그아웃 되었습니다. 브라우저에서도 로그아웃을 하려면 로그아웃 버튼을 클릭해주세요.",
    i00175: "애플리케이션에서 로그아웃 되었습니다.",
    i00176: "로그아웃",
    i00177: "알림",
    i00178: "로그아웃 하시겠습니까?",
    i00179: "확인",
    i00180: "채널 초대 수락",
    i00181: "채널의 초대를 수락하시려면 아래 수락 버튼을 눌러주세요.",
    i00182: "초대 수락",
    i00183: "초대 시간이 만료되었거나 초대 링크가 유효하지 않습니다.",
    i00184: "{channel} 채널에서 초대를 받았습니다.",
    i00185: "채널에 초대한 멤버 정보 조회 중 오류가 발생했습니다.",
    i00186: "초대중인 멤버",
    i00187: "초대일시",
    i00188: "오류가 발생했습니다. 다시 불러오기",
    i00189: "초대 메일을 전송했습니다.",
    i00190: "멤버 초대 중 오류가 발생 했습니다.",
    i00191: "초대 메일 재전송",
    i00192: "이메일 형식이 올바르지 않습니다.",
    i00193: "이미 초대한 멤버입니다.",
    i00194: "전체 메시지",
    i00195: "챗봇, 문자, 알림톡, 이메일로 메시지를 전송해서 고객의 재방문율을 높혀보세요.",
    i00196: "메시지 조회 중 오류가 발생했습니다.",
    i00197: "새 메시지 작성하기",
    i00198: "새 메시지",
    i00199: "메시지",
    i00200: "고객에게 전송할 메시지를 작성하세요.",
    i00201: "타겟팅",
    i00202: "메시지를 받을 고객을 설정 할 수 있습니다.",
    i00203: "전송 설정",
    i00204: "메시지를 보낼 시간, 종류를 설정 할 수 있습니다.",
    i00205: "메시지를 입력하세요.",
    i00206: "전송 시간",
    i00207: "날짜 선택",
    i00209: "광고성 메시지는 한국의 경우 오후 9시부터 다음 날 오전 8시까지 전송이 제한됩니다.\n국가별로 제한 시간은 다를 수 있습니다. 해당사항 위반시 책임은 고객사에게 있습니다.",
    i00210: "시간대 기준",
    i00211: "오프라인 고객에게 전송",
    i00212: "고객이 오프라인일 때 문자메시지(SMS)와 이메일로 전송하며, 고객이 홈페이지에 접속해 있는 경우에는 팝업 메시지 형태로 전송합니다.",
    i00213: "문자메시지",
    i00214: "이메일",
    i00215: "카카오 알림톡",
    i00216: "광고성 메시지",
    i00217: "광고성 문자메시지를 전송할 경우에 체크하면, 메시지 첫줄에 (광고)라는 문구와 메시지 하단에 수신거부 전화번호가 같이 전송됩니다.",
    i00218: "오프라인 고객에게 전송",
    i00219: "보내기",
    i00220: "임시저장",
    i00221: "메시지 저장 중 오류가 발생했습니다.",
    i00222: "메시지 정보 조회 중 오류가 발생했습니다.",
    i00223: "메시지가 저장되었습니다.",
    i00224: "이미지 첨부",
    i00225: "파일 첨부",
    i00226: "제목",
    i00227: "상태",
    i00228: "전송",
    i00229: "확인",
    i00230: "전송시간",
    i00231: "등록시간",
    i00232: "조회된 메시지가 없습니다.",
    i00233: "초안",
    i00234: "전송중",
    i00235: "전송됨",
    i00236: "취소",
    i00237: "전송실패",
    i00238: "메시지 삭제 중 오류가 발생했습니다.",
    i00239: "메시지를 삭제 하시겠습니까?",
    i00240: "메시지를 전송 하시겠습니까?",
    i00241: "전송",
    i00242: "메시지 전송을 요청했습니다.",
    i00243: "메시지 처리 중 오류가 발생했습니다.",
    i00244: "{time}에 메시지 전송을 예약했습니다.",
    i00245: "총 {total}명의 고객에게 메시지를 전송하고, 오프라인 메시지는 연락처가 있는 {contact}명의 고객에게 전송합니다.",
    i00246: "총 {total}명의 고객에게 메시지를 전송합니다.",
    i00247: "메시지 전송시 필터를 다시 적용하기 때문에 실제 메시지를 받는 고객이 달라질 수 있습니다.",
    i00248: "고객 조회 중 오류가 발생했습니다.",
    i00249: "메시지를 전송 할 대상이 없습니다. 다른 조건을 입력 해보세요.",
    i00250: "메시지 전송을 예약 하시겠습니까?",
    i00251: "메시지 예약 취소 처리중 오류가 발생했습니다.",
    i00252: "예약 취소",
    i00253: "예약된 메시지를 취소 하시겠습니까?",
    i00254: "모든 고객",
    i00255: "예약 시간은 현재 시간 이후로 설정 해주세요.",
    i00256: "서비스 구독",
    i00257: "무료",
    i00258: "PRO",
    i00259: "PREMIUM",
    i00260: "ENTERPRISE",
    i00261: "업그레이드",
    i00262: "현재 사용 중",
    i00263: "다운그레이드",
    i00264: "BASIC",
    i00265: "구독 정보 조회 중 오류가 발생했습니다.",
    i00266: "원",
    i00267: "월",
    i00268: "제공하는 기능은 모든 플랜에서 사용할 수 있습니다. 사용량에 맞게 플랜을 선택해보세요.",
    i00269: "별도문의",
    i00270: "플랜",
    i00271: "구독 변경 중 오류가 발생했습니다.",
    i00272: "구독 변경",
    i00273: "구독 취소",
    i00274: "구독을 취소하시겠습니까?\n취소 즉시 무료 플랜으로 변경되며 기능에 제약이 생길 수 있습니다.",
    i00275: "구독 취소 처리 중 오류가 발생했습니다.",
    i00276: "구독 기간",
    i00277: "다음 결제일",
    i00278: "자동 결제 취소됨",
    i00279: "구독 시작일",
    i00280: "구독 종료일",
    i00281: "청구 금액",
    i00282: "구독 정보가 없습니다.",
    i00283: "제공중인 플랜",
    i00284: "가격",
    i00285: "문자메시지 전송은 별도의 금액입니다.",
    i00286: "부가세(10%)는 별도 추가됩니다.",
    i00287: "VAT 별도",
    i00288: "연 결제 10% 할인",
    i00289: "결제 정보",
    i00290: "구독 및 문자 전송 시 사용할 결제수단을 설정해 주세요",
    i00291: "카드",
    i00292: "예치금",
    i00293: "예치금 정보 조회 중 오류가 발생했습니다.",
    i00294: "거래 정보가 없습니다.",
    i00295: "일자",
    i00296: "사용처",
    i00297: "유형",
    i00298: "금액",
    i00299: "구독",
    i00300: "출금",
    i00301: "입금",
    i00302: "카드정보 조회 중 오류가 발생했습니다.",
    i00303: "카드",
    i00304: "기본 카드",
    i00305: "카드 등록",
    i00306: "카드 번호",
    i00307: "유효기간",
    i00308: "비밀번호 앞 2자리",
    i00309: "생년월일 6자리 (법인카드는 사업자번호 10자리)",
    i00310: "결제정보를 받을 이메일을 입력하세요",
    i00311: "카드정보 등록 중 오류가 발생했습니다.",
    i00312: "기본 카드로 설정",
    i00313: "카드정보 삭제 중 오류가 발생했습니다.",
    i00314: "카드 삭제",
    i00315: "카드를 삭제하시겠습니까? 삭제한 카드 정보는 복구할 수 없습니다.",
    i00316: "등록된 카드 정보가 없습니다.",
    i00317: "기본 결제카드 등록 중 오류가 발생했습니다.",
    i00318: "카드 거래내역 조회 중 오류가 발생했습니다.",
    i00319: "카드 거래내역",
    i00320: "카드 목록",
    i00321: "기본 결제 카드가 변경되었습니다.",
    i00322: "구독 및 결제",
    i00323: "등록된 결제수단이 없습니다. 결제 수단을 등록해주세요.",
    i00324: "{plan} 플랜을 구독하시겠습니까?",
    i00325: "잔액이 부족해서 메시지를 전송할 수 없습니다.",
    i00326: "오프라인 전송 예상 금액은 {offlineAmount}원 입니다.",
    i00327: "문자 충전",
    i00328: "SMS 비용은 문자 충전 금액에서 차감되며 구독 플랜과는 별도로 운영됩니다. 사용량에 따라 충전해서 사용해 보세요.",
    i00329: "잔액",
    i00330: "금액 선택",
    i00331: "충전",
    i00332: "충전 금액",
    i00333: "문자를 충전 하시겠습니까? 충전 금액: {amount}원",
    i00334: "충전 금액을 입력하세요.",
    i00335: "충전 금액은 100원 이상으로 입력하세요.",
    i00336: "문자 충전 정보 조회 중 오류가 발생했습니다.",
    i00337: "변경 사항 저장",
    i00338: "결제 카드 등록이 안됐거나 카드 정보가 잘못되었습니다.",
    i00339: "문자 자동충전 정보를 저장 했습니다.",
    i00340: "자동 충전할 기준 금액을 입력하세요",
    i00341: "최소 금액은 5000원 이상입니다.",
    i00342: "충전할 금액보다 클 수 없습니다.",
    i00343: "충전 기준 금액이 현재 잔액보다 크면 저장과 동시에 결제가 되니 참고해 주시기 바랍니다.",
    i00344: "문자 충전 중 오류가 발생했습니다.",
    i00345: "문자 충전을 완료 했습니다.",
    i00346: "카드정보가 유효하지 않습니다. 확인 후 다시 등록해주세요.",
    i00347: "변경 사항을 저장한 후에 전송하시겠습니까?",
    i00348: "데모 페이지",
    i00349: "등록한 챗봇 설정을 실제 데모 페이지에서 확인해보세요.",
    i00350: "데모 페이지로 이동",
    i00351: "오프라인 전송",
    i00352: "잔액이 부족합니다.",
    i00353: "전송 대상 없음",
    i00354: "예약 대기",
    i00355: "환불 규정",
    i00356: "전송 전",
    i00357: "작성중인 메시지",
    i00358: "대기중인 메시지",
    i00359: "전송된 메시지",
    i00360: "상세",
    i00361: "확인 방법",
    i00362: "확인 시간",
    i00363: "채팅",
    i00364: "전화번호",
    i00365: "메시지",
    i00366: "국내 문자(SMS, LMS) 발신 번호 설정 ",
    i00367: "마케팅 메시지 전송 또는 회신을 받아야 할 경우에는 발신 번호를 설정하세요.\n발신 번호 미등록 혹은 비활성화시 상담 답변 알림은 스윙챗 기본 번호로 전송됩니다.",
    i00368: "발신 번호 등록 ",
    i00369: "ARS에서 전달받은 인증번호를 입력하세요.",
    i00370: "국내 발신 번호",
    i00371: "인증번호",
    i00372: "{phone} 번호를 발신 번호로 등록하시겠습니까?\n\nARS 인증시스템에서 받은 인증번호 입력 후 확인 버튼을 누르면 등록이 완료됩니다.",
    i00373: "다시 받기",
    i00374: "입력 시간을 초과했습니다.",
    i00375: "발신 번호 등록 요청 중 오류가 발생했습니다.",
    i00376: "전화번호가 유효하지 않습니다.",
    i00377: "유효한 인증번호를 입력하세요.",
    i00378: "숫자만 입력하세요.",
    i00379: "국내 발신 번호가 등록 되었습니다. ",
    i00380: "국내 발신 번호가 삭제 되었습니다 ",
    i00381: "국내 발신 번호 삭제 중 오류가 발생했습니다. ",
    i00382: "국내 발신 번호 등록 중 오류가 발생했습니다. ",
    i00383: "국내 발신 번호 삭제 ",
    i00384: "국내 발신 번호를 삭제 하시겠습니까?\n삭제 후에는 마케팅 메시지 전송 및 회신이 불가능합니다. ",
    i00385: "문자 충전 잔액: {amount}원",
    i00386: "문자 충전 금액 잔액이 부족하여 이메일만 전송됩니다.",
    i00387: "사용 예정 금액",
    i00388: "충전 금액을 입력하세요.",
    i00389: "잔액이 다음 보다 작으면 (충전 기준 금액)",
    i00390: "직접 입력",
    i00391: "자동 충전",
    i00392: "자동 충전 기준 금액을 입력하세요.",
    i00393: "다음 금액을 충전",
    i00394: "상담 운영시간에 고객이 상담을 요청하면 자동으로 전송되는 메시지입니다.",
    i00395: "고객이 상담 요청 후 취소 버튼에 표시될 문구 입니다.",
    i00396: "상담 운영시간이 아닌 경우 고객이 상담을 요청하면 자동으로 전송되는 메시지입니다.",
    i00397: "상담원이 연결되면 자동으로 전송되는 메시지입니다.",
    i00398: "상담원이 상담 종료 버튼을 누르면 자동으로 전송되는 메시지입니다.",
    i00399: "로그아웃",
    i00400: "계정 설정",
    i00401: "로그인 계정",
    i00402: "계정 정보 수정 중 에러가 발생 했습니다.",
    i00403: "계정 정보 수정을 완료 했습니다.",
    i00404: "비밀번호 변경",
    i00405: "비밀번호를 수정 했습니다.",
    i00406: "잘못된 비밀번호입니다. 다시 시도하세요.",
    i00407: "새 비밀번호 확인",
    i00408: "새 비밀번호를 다시 입력하세요.",
    i00409: "비밀번호가 일치하지 않습니다.",
    i00410: "현재 잔액이 충전 기준 금액보다 적어서 저장 후 즉시 결제가 됩니다.",
    i00411: "유료 플랜을 구독중입니다. 구독을 취소하고 삭제하세요.",
    i00412: "문자 자동 충전을 사용중입니다. 자동 충전을 취소하고 삭제하세요.",
    i00413: "추가 된 운영시간이 없습니다. 상담 운영시간을 추가하세요.",
    i00415: "초대 할 사용자의 이메일을 입력하세요.",
    i00416: "초대 링크 보내기",
    i00417: "초대 취소",
    i00418: "멤버 초대를 취소 했습니다.",
    i00419: "초대 취소를 하시면 {username}은 초대 링크에 접근 할 수 없습니다.",
    i00420: "초대 링크 복사",
    i00421: "상태",
    i00422: "대기중",
    i00423: "만료됨",
    i00424: "메시지 제목을 입력하세요.",
    i00425: "예약 시간을 입력하세요.",
    i00426: "예약",
    i00427: "다시 보내기",
    i00428: "이 내용으로 다시 보내시겠습니까?\n메시지 작성 화면으로 이동합니다.",
    i00429: "카카오 알림톡 연동",
    i00430: "카카오 알림톡",
    i00431: "알림톡은 문자보다 우선적으로 보내지며, 알림톡 발송에 실패하거나 사용자가 차단한 경우에는 자동으로 문자(SMS, LMS)로 대체 발송됩니다.\n" +
        "마케팅 메시지는 문자(SMS, LMS)로 발송됩니다.",
    i00432: "연동하기",
    i00433: "검색용 아이디",
    i00434: "담당자 휴대폰번호",
    i00435: "카테고리",
    i00436: "대분류",
    i00437: "중분류",
    i00438: "소분류",
    i00439: "인증 요청하기",
    i00440: "인증",
    i00441: "인증 토큰 입력",
    i00442: "카카오톡 비즈메시지로 발송된 인증번호를 입력하세요.",
    i00443: "카카오톡 채널 정보를 확인해 주세요.\n검색용 ID 또는 휴대폰번호가 잘못되었습니다.",
    i00444: "유효하지 않는 인증번호 입니다.",
    i00445: "연동된 카카오 채널",
    i00446: "연동해제",
    i00447: "다른 채널에 이미 연동된 카카오 채널입니다.",
    i00448: "알림톡 연동을 해제하시겠습니까?",
    i00449: "연동 해제",
    i00450: "채널에 카카오 채널 연동정보가 없습니다.",
    i00451: "검수중",
    i00452: "알림톡을 사용하려면 \"카카오 비즈니스\"에서 카카오톡 채널을 등록하시고 \"비즈니스 인증\"을 꼭 받으셔야 합니다.\n카카오톡 채널 프로필 설정에서 채널 공개를 ON으로 변경하세요",
    i00453: "연동할 수 없는 카카오 채널입니다. 고객센터로 문의해 주세요.",
    i00454: "구독 내역",
    i00455: "한달 활성 사용자(MAU)",
    i00456: "명",
    i00457: "스윙챗만의 차별화된 부가서비스를 경험하세요!",
    i00458: "플러스팩",
    i00459: "부가서비스",
    i00460: "마케팅 기능",
    i00461: "타겟 고객들에게 단체 선별 메시지 전송, 자동 상품추천 등 고도화된 마케팅 툴 제공",
    i00462: "상담효율화",
    i00463: "상담 통계데이터를 활용한 이슈관리 및 효율화",
    i00464: "선택 플랜 금액 확인",
    i00465: "플랜 가격",
    i00466: "부가 서비스",
    i00467: "합계",
    i00468: "한달 활성 사용자(MAU) {mau}명 이하",
    i00469: "전송 내역",
    i00470: "마케팅 및 상담 답변으로 사용된 문자메시지 전송 내역입니다.",
    i00471: "구독중",
    i00472: "부가서비스 변경",
    i00473: "구독을 변경했습니다.",
    i00474: "부가 서비스를 변경했습니다.",
    i00475: "부가서비스 설정을 변경하시겠습니까?",
    i00476: "구독 중인 플랜",
    i00477: "문자메시지 전송은 별도의 금액입니다.",
    i00478: "부가세(10%)는 별도 추가됩니다.",
    i00479: "무료 체험기간",
    i00480: "{days}일 남음",
    i00481: "14일 무료 체험후 구독료가 결제됩니다.",
    i00482: "{plan} 플랜을 구독하시겠습니까? 14일 무료이용 후에 자동 결제됩니다.",
    i00483: "무료 체험이 {days}일 남았습니다.",
    i00484: "한달 활성 사용자(MAU)보다 적은 플랜은 선택할 수 없습니다.",
    i00485: "결제 금액 없음",
    i00486: "구독 내역이 없습니다.",
    i00487: "선택안함",
    i00488: "환불",
    i00489: "체크카드는 구독을 결제할 수 없습니다.",
    i00490: "PLATINUM",
    i00491: "초대 받은 계정과 다른 계정으로 로그인 되어 있습니다.",
    i00492: "반품/교환 주소를 입력하세요.",
    i00493: "대상 고객",
    i00494: "시작 조건",
    i00495: "노출 기간",
    i00496: "메시지",
    i00497: "사이트에 처음으로 방문 하는 모든 고객",
    i00498: "사이트에 방문 했을 때",
    i00499: "기간 설정",
    i00500: "클릭해서 노출 기간을 설정하세요.",
    i00501: "메시지 노출 기간을 선택하세요.",
    i00502: "{name} 메시지를 저장했습니다.",
    i00503: "항상",
    i00504: "쇼핑몰 로그인 아이디",
    i00505: "목록",
    i00506: "새 메시지 등록",
    i00507: "내용",
    i00508: "등록일",
    i00509: "사용 유무",
    i00510: "메시지를 삭제 하시겠습니까?",
    i00511: "{name} 메시지를 삭제했습니다.",
    i00512: "상품명을 입력하세요.",
    i00513: "상품 추가",
    i00514: "상품을 추가하세요.",
    i00515: "사용",
    i00516: "발송 요일",
    i00517: "발송 시간",
    i00518: "탬플릿에서 선택",
    i00519: "메시지 발송 조건을 모두 입력하세요.",
    i00520: "FAQ 챗봇으로 설정을 변경했습니다.",
    i00521: "{name} 설정 중 에러가 발생했습니다.\n잠시후 다시 시도해 주세요.",
    i00522: "메시지 노출 주기",
    i00523: "분 마다",
    i00524: "시간 마다",
    i00525: "일 마다",
    i00526: "메시지 노출 주기를 입력하세요.",
    i00527: "시스템 메시지 포함",
    i00528: "{name} 기능을 활성화 했습니다.",
    i00529: "{name} 기능을 비활성화 했습니다.",
    i00530: "오프라인 메시지",
    i00531: "오프라인 메시지란?\n사이트에 접속해 있지 않는 고객에게 SMS, 이메일로 메시지를 전달합니다.",
    i00532: "시간을 선택하세요.",
    i00533: "웰컴씬(첫화면)에서 주요기능을 이미지 카드로 안내",
    i00534: "자세히 알아보기",
    i00535: "챗봇 버튼 설정",
    i00536: "챗봇 버튼의 사이즈, 위치를 디바이스 별로 설정합니다.",
    i00537: "데스크탑",
    i00538: "모바일",
    i00539: "사이즈",
    i00540: "작게",
    i00541: "중간",
    i00542: "크게",
    i00543: "위치",
    i00544: "세부위치",
    i00545: "챗봇 버튼 설정을 저장했습니다.",
    i00546: "화이트리스트(Whitelist) 설정",
    i00547: "- 화이트리스트를 설정하지 않으면 모든 페이지에서 스윙챗 버튼이 노출됩니다.\n- 화이트리스트를 설정하시면 해당 주소에서만 노출됩니다.\n- 주소뒤에 *을 추가하시면 입력하신 주소로 시작하는 모든 페이지에서 노출됩니다.\n예시) https://console.swing.chat/*",
    i00548: "이미지 비율대로",
    i00549: "화이트리스트로 등록할 주소를 입력하세요",
    i00550: "카카오 프로필",
    i00551: "알림톡 도착",
    i00552: "알림톡으로 발송.(건당5원)",
    i00553: "알림톡 발송 실패시 문자메시지를 발송합니다.(SMS 8원, LMS 27원)",
    i00554: "이메일 주소가 있으면 알림톡, 문자메시지 발송 시 함께 발송합니다.(무료)",
    i00555: "⚠️ 알림톡을 먼저 연동해 주세요. 연동 후에 아래 기능들을 사용할 수 있습니다.",
    i00556: "연동하러 가기",
    i00557: "카카오 비즈니스 채널의 검색용 아이디를 입력하세요.",
    i00558: "챗봇 이름을 입력하세요.",
    i00559: "페이지를 찾을 수 없습니다.",
    i00560: "접근 권한이 없습니다.",
    i00561: "서비스 오류",
    i00562: "서비스 이용에 불편을 드려 죄송합니다.\n시스템 에러가 발생하여 페이지를 표시할 수 없습니다. 고객센터에 문의하시거나 잠시 후 다시 시도해 주세요.",
    i00563: "메시지",
    i00564: "프로필 수집창에 보여질 메시지를 설정합니다.",
    i00565: "메시지를 입력하세요.",
    i00566: "프로필 수집 항목",
    i00567: "프로필 수집창에 보여질 수집 항목을 설정합니다.",
    i00568: "입력 힌트를 입력하세요.",
    i00569: "입력해 주세요.",
    i00570: "제출하기",
    i00571: "추가할 항목을 선택하세요.",
    i00572: "프로필 수집 정보를 저장했습니다.",
    i00573: "프로필 수집 항목을 한 개 이상 추가하세요.",
    i00574: "챗봇 빌더",
    i00575: "전체 메시지",
    i00576: "작성중 메시지",
    i00577: "대기중 메시지",
    i00578: "전송 완료 메시지",
    i00579: "회원가입이 완료되었습니다!",
    i00580: "{name}님 환영합니다. 가입하신 스윙챗 아이디는 {username}입니다. 로그인 후 스윙챗의 모든 서비스를 이용해 보세요.",
    i00581: "비밀번호는 숫자, 소문자, 특수문자 1개 이상을 포함하고 8자리 이상이여야 합니다.",
    i00582: "이메일 또는 비밀번호 오류 횟수가 초과되었습니다. 15분 후 다시 시도해주세요.",
    i00583: "이메일 또는 비밀번호 {count}회 오류입니다. 지금부터 {remainCount}회 입력 오류 시 15분간 접속이 불가합니다.",
    i00584: "고객님의 개인정보 보호를 위해 6개월 이상 비밀번호를 변경하지 않은 경우 새로운 비밀번호로 변경하시기를 권장하고 있습니다.",
    i00585: "기존 비밀번호",
    i00586: "기존 비밀번호를 입력하세요",
    i00587: "비밀번호 변경하기",
    i00588: "다음에 변경하기",
    i00589: "아래 이동 버튼을 눌러 채널 목록으로 이동해주세요.",
    i00590: "채널 목록으로 이동",
    i00591: "권한 그룹 관리",
    i00592: "채널을 운영하는 멤버 권한에 대해 그룹 단위로 관리할 수 있습니다.",
    i00593: "권한 그룹 생성",
    i00594: "권한 그룹 이름",
    i00595: "그룹 이름",
    i00596: "권한 그룹 생성",
    i00597: "그룹 코드를 입력하세요. 예) MANAGER, MEMBER",
    i00598: "그룹 이름을 입력하세요",
    i00599: "기본 정보",
    i00600: "권한 설정",
    i00601: "부여 권한 수",
    i00602: "유형",
    i00603: "전체",
    i00604: "기본",
    i00605: "그룹 코드",
    i00606: "코드는 영문, 숫자, '-', '_' 만 입력 가능합니다.",
    i00607: "그룹",
    i00608: "수정",
    i00609: "권한 그룹 수정",
    i00610: "중복된 권한 코드입니다.",
    i00611: "권한 그룹 코드",
    i00612: "확인/수정",
    i00613: "기본",
    i00614: "기본 권한 그룹은 수정이 불가하며, 채널 삭제 권한은 채널을 생성한 Admin 계정에만 부여됩니다.",
    i00615: "기본 권한 그룹 확인",
    i00616: "권한 그룹을 삭제하시겠습니까?",
    i00617: "권한 그룹을 삭제하면 그룹에 속한 모든 멤버의 권한이 사라집니다.\n멤버 관리에서 그룹을 다시 할당할 수 있습니다.",
    i00618: "멤버 정보 수정",
    i00619: "멤버 정보를 수정했습니다.",
    i00620: "다중 인증",
    i00621: "다중 인증 설정",
    i00622: "계정 인증",
    i00623: "인증 이메일 전송",
    i00624: "인증 상태",
    i00625: "이메일 인증이 완료되었습니다.",
    i00626: "계정의 이메일({email})로 인증이 완료되었습니다.",
    i00627: "유효하지 않은 토큰입니다. 이메일 인증요청을 다시 해주세요.",
    i00628: "유효하지 않은 토큰이거나 다른 계정으로 로그인 되었습니다.",
    i00629: "등록된 계정 이메일로 인증코드가 전송되었습니다.",
    i00630: "인증 코드 입력",
    i00631: "인증 코드",
    i00632: "인증 코드 6자리를 입력하세요.",
    i00633: "채널 목록 가기",
    i00634: "다중 인증 설정 시작하기",
    i00635: "하단 버튼을 누르면 기존 계정에서 로그아웃되며 해당 이메일로 인증코드가 전송됩니다.\n재로그인하고 인증코드로 인증하시면 다중 인증 설정이 완료됩니다.\n(인증코드는 5분간 유효하며 시간을 초과하는 경우 다시 진행해주세요.)\n※ 계정의 이메일은 수신 가능한 이메일이어야 합니다.",
    i00636: "다중 인증 설정 및 재로그인",
    i00637: "인증하지 않기",
    i00638: "자동 로그아웃 및 인증코드 확인",
    i00639: "재로그인 및 인증코드 입력",
    i00640: "다중 인증 완료",
    i00641: "다중 인증 해제",
    i00642: "다중 인증 취소",
    i00643: "다중 인증 설정이 완료되었습니다.",
    i00644: "다중 인증 상태가 대기중이 아닙니다.",
    i00645: "유효하지 않은 인증 코드 입니다.",
    i00646: "인증 완료",
    i00647: "인증 대기중",
    i00648: "인증 필요",
    i00649: "다중 인증을 해제하시겠습니까?",
    i00650: "인증을 해제할 경우 다중 인증을 설정한 채널의 접근이 차단됩니다.",
    // i00650: "인증을 해제할 경우 다중 인증을 설정한 채널의 접근이 차단되며, 기존 로그인되어 있던 모든 기기에서 로그아웃됩니다.",
    i00651: "다중 인증이 해제되었습니다.",
    i00652: "계정정보에 문제가 발생했습니다. 고객센터에 문의해 주세요.",
    i00653: "보안",
    i00654: "다중 인증 설정",
    i00655: "채널 관리자 메뉴에 접근할 수 있는 전체 계정에 대해 다중 인증을 적용합니다.",
    i00656: "다중 인증 설정으로 채널을 더 안전하게 보호할 수 있습니다.",
    i00657: "다중 인증이 활성화 되었습니다.",
    i00658: "다중 인증이 해제 되었습니다.",
    i00659: "다중 인증이 필요한 채널입니다.",
    i00660: "계정 설정에서 다중 인증을 설정해주세요.\n인증하지 않은 계정은 채널 사용이 불가능합니다.\n※ 스윙챗은 채널을 안전하게 보호하기 위한 다중 인증 기능을 지원하고 있습니다.",
    i00661: "계정 설정 가기",
    i00662: "현재 계정에 다중 인증이 설정되어 있지 않습니다.\n계정 설정에서 먼저 다중 인증을 진행해주세요.\n계정 설정으로 이동하시겠습니까?",
    i00663: "계정 설정으로 이동",
    i00664: "다중 인증 설정을 위해서는 계정의 이메일로 인증코드 메일을 수신 할 수 있어야 합니다.\n\n메일을 수신할 수 없는 계정은 다중 인증 설정이 불가능하오니 확인 후 진행해 주세요.",
    i00665: "로그아웃 후 인증코드 받기",
    i00666: "아니오",
    i00667: "다중 인증 설정을 통해 채널 보안 수준을 높일 수 있습니다.\n인증하지 않은 모든 멤버는 즉시 채널 접근이 차단됩니다.\n다중 인증을 활성화 하시겠습니까?",
    i00668: "다중 인증 설정을 해제하면 채널 보안 수준이 하향됩니다.\n다중 인증을 비활성화 하시겠습니까?",
    i00669: "설정하기",
    i00670: "해제하기",
    i00671: "{name} 정보를 입력하세요.",
    i00672: "아웃바운드",
    i00673: "아웃바운드 관리",
    i00674: "카카오 알림톡을 보내기 위한 알림톡 템플릿을 만듭니다. 절차(등록 → 검수 → 신청완료)가 완료된 이후 아웃바운드에서 사용할 수 있습니다",
    i00675: "알림톡 템플릿 만들기",
    i00676: "카테고리 그룹",
    i00677: "카테고리 그룹을 선택하세요.",
    i00678: "카테고리",
    i00679: "카테고리를 선택하세요.",
    i00680: "알림톡 템플릿 이름",
    i00681: "템플릿 이름을 입력하세요.",
    i00682: "버튼명",
    i00683: "버튼 종류",
    i00684: "모바일 링크(필수 사항)",
    i00685: "PC 링크(선택 사항)",
    i00686: "최대 140자 이내, https://또는 http:// 프로토콜 필수, 변수사용가능",
    i00687: "웹링크",
    i00688: "버튼 클릭 시, 인앱 브라우저에서 웹페이지 실행\n- 모바일(필수) / PC(선택)에서 실행할 URL을 각각 설정\n- URL 링크를 변수로 설정할 수 있으며, 링크를 변수설정 하는 과정에서 프로토콜 영역은 고정영역으로 등록해야함\n예시) http://#{url}, https://#{url}",
    i00689: "배송조회",
    i00690: "알림톡 템플릿 내용에 택배사, 송장번호가 포함된경우 카카오 검색 배송조회 페이지로 이동하는 배송조회하기 버튼을 자동으로 생성\n\n배송조회 버튼이 노출되는 택배사\n- 지원 가능 택배사 : 우체국택배, 로젠택배, 일양로지스, FedEx, 한진택배, 경동택배, 합동택배, 롯데택배, 세방택배, 한덱스택배, 농협택배, 호남택배, 천일택배, 건영택배, 대신택배, CU편의점택배, CVSnet편의점택배, TNT ExpressUSPS, EMS, DHL, 굿투럭\n\n(이외 택배사의 경우 배송조회 버튼이 노출되지 않음)",
    i00691: "http:// 또는 https://로 시작해야 합니다.",
    i00692: "강조표기 제목",
    i00693: "강조표기 보조문구",
    i00694: "버튼 추가",
    i00695: "내용을 입력하시면 알림톡 내용을 미리 확인할 수 있습니다.",
    i00696: "기본형(내용, 버튼 웹링크), 강조표기형(제목, 내용, 버튼 웹링크)에는 변수를 넣을 수 있습니다.\n" +
        "변수가 들어간 템플릿으로 사용자별 맞춤 메시지를 보낼 수 있습니다.\n\n" +
        "예시)\n" +
        "#{name}님 안녕하세요.\n" +
        "스윙챗 회원이 되신걸 환영합니다.",
    i00697: "기본형",
    i00698: "강조표기형",
    i00699: "카카오 알림톡 아웃바운드를 등록했습니다.",
    i00700: "카카오 알림톡 아웃바운드를 수정했습니다.",
    i00701: "카카오 알림톡 아웃바운드 등록 중 오류가 발생했습니다.",
    i00702: "카카오 알림톡 아웃바운드 수정 중 오류가 발생했습니다.",
    i00703: "검수요청",
    i00704: "카카오 알림톡 아웃바운드를 삭제했습니다.",
    i00705: "카카오 알림톡 아웃바운드 삭제 중 오류가 발생했습니다.",
    i00706: "알림톡 템플릿 만들기",
    i00707: "카카오 알림톡을 보내기 위한 알림톡 템플릿을 만듭니다. 절차(등록 → 검수 → 신청완료)가 완료된 이후 아웃바운드에서 사용할 수 있습니다.",
    i00708: "버튼 수정",
    i00709: "알림톡 템플릿 검수를 요청했습니다.",
    i00710: "알림톡 템플릿 검수 요청 중 오류가 발생했습니다.",
    i00711: "알림톡 템플릿 검수 취소를 요청했습니다.",
    i00712: "알림톡 템플릿 검수 취소 요청 중 오류가 발생했습니다.",
    i00713: "알림톡 템플릿 승인을 취소했습니다.",
    i00714: "알림톡 템플릿 승인을 취소 중 오류가 발생했습니다.",
    i00715: "검수요청 취소",
    i00716: "아웃바운드",
    i00717: "알림톡",
    i00718: "알림톡 템플릿을 선택해주세요.\n템플릿이 없다면 새로 만들 수 있습니다.",
    i00719: "전화번호는 숫자만 입력 가능합니다.",
    i00720: "전송 결과",
    i00721: "수정시간",
    i00722: "템플릿을 다운로드해서 알림톡 대상 고객을 작성한 후 업로드 해주세요.",
    i00723: "알림톡을 전송할 대상 고객이 없습니다. 수신할 사용자를 추가해 주세요.",
    i00724: "등록된 대상 고객을 모두 삭제하시겠습니까?",
    i00725: "승인취소",
    i00726: "유형",
    i00727: "SSO 로그인",
    i00728: "연동되어 있는 SSO 계정을 입력해서 로그인을 진행하세요.",
    i00729: "스윙챗에 SSO 로그인을 처음 하시는 경우에는 SSO 공급자에게 스윙챗 로그인 링크를 문의후 해당 링크로 접속해주세요.",
    i00730: "사용할 수 없는 계정입니다.",
    i00731: "다음",
    i00732: "스윙챗 계정으로 로그인 하러가기",
    i00733: "SSO 계정",
    i00734: "SSO 계정(ID)를 입력하세요.",



    i20001: "챗봇 종류 및 주요 기능 설정",
    i20002: "커머스 챗봇에 대한 설정입니다. 각 기능 별 처리 가능 업무를 확인하신 후 On/Off 를 설정해서 사용해 보세요.",
    i20003: "FAQ 등록",
    i20004: "자주 묻는 질문, 답변을 등록해 보세요. 상담사가 없어도 등록된 질문과 답변을 토대로 챗봇이 알아서 응답합니다.",
    i20005: "FAQ가 등록된 엑셀을 업로드해주세요.",
    i20006: "임계치",
    i20007: "자주묻는 질문의 임계치를 성정 합니다. 대화 엔진에서 돌려준 정확도를 비교합니다.",
    i20008: "배송조회",
    i20009: "처리하기 어려운 배송 문의를 챗봇이 알아서 응대합니다. 아래와 같이 묻는 고객들을 응대할 수 있습니다.",
    i20010: "어제 산 제품 어디까지 왔는지 확인하고 싶어요.",
    i20011: "어제 도착 예정이던 상품이 아직 도착을 안했어요.",
    i20012: "반품 접수, 내역 조회",
    i20013: "반품 접수 및 문의를 이제 챗봇이 처리합니다. 아래와 같이 묻는 고객들을 응대할 수 있습니다.",
    i20014: "어제 주문한 상품 취소하고 싶어요.",
    i20015: "한 번 입었는데 반품 가능할까요?",
    i20016: "주문 변경, 취소",
    i20017: "교환 접수 및 문의를 이제는 챗봇이 처리합니다. 아래와 같이 묻는 고객들을 응대할 수 있습니다.",
    i20018: "사이즈가 너무 작아요. 한 치수 큰 걸로 보내주세요.",
    i20019: "교환하고 싶은데 무료인가요?",
    i20020: "자주 묻는 질문",
    i20021: "자주 묻는 질문을 등록해 보세요",
    i20022: "쇼핑몰 이름이 왜 xx몰 이에요?",
    i20023: "대표 이름이 머야?",
    i20024: "등록 성공",
    i20025: "챗봇 주요기능 설정이 등록되었습니다.",
    i20026: "등록 실패",
    i20027: "임계치 점수",
    i20028: "FAQ 데이터가 등록되었습니다.",
    i20029: "파일이 정상적으로 등록 되었습니다.",
    i20030: "챗봇 스타일 설정",
    i20031: "챗봇의 아이콘 테마 등을 설정합니다. 챗봇 색상 및 글자 크기, 버튼 설정을 통해 고객님만의 챗봇으로 꾸며보세요.",
    i20032: "테마 색상",
    i20033: "버튼 텍스트",
    i20034: "채팅 버튼 옆에 노출되는 텍스트를 입력해서 상담을 유도해 보세요",
    i20035: "아이콘",
    i20036: "이곳을 클릭해 아이콘을 등록해 보세요",
    i20037: "글자 크기",
    i20038: "크게",
    i20039: "중간",
    i20040: "작게",
    i20041: "등록 성공",
    i20042: "챗봇 스타일 설정이 등록 되었습니다",
    i20043: "챗봇 스타일 설정 이미지가 등록되었습니다",
    i20044: "삭제 성공",
    i20045: "챗봇 스타일 설정 이미지가 삭제 되었습니다",
    i20046: "챗봇 이름이 등록 되었습니다.",
    i20047: "편집",
    i20048: "완료",
    i20049: "등록된 이미지를 확인하세요.",
    i20050: "(이미지 변경은 기존 이미지 삭제 후 다시 등록해야 합니다.)",
    i20051: "오류",
    i20052: "설정 등록 중 오류가 발생 했습니다",
    i20053: "질문을 입력하세요",
    i20054: "답변을 입력하세요",
    i20055: "잘못된 요청 입니다.",
    i20056: "질문을 입력하세요",
    i20057: "입력된 질문이 없습니다.",
    i20058: "답변을 입력하세요",
    i20059: "입력된 답변이 없습니다.",
    i20060: "FAQ 등록 완료",
    i20061: "FAQ 질문/답변 등록 작업을 완료 했습니다.",
    i20063: "FAQ 등록 작업 완료",
    i20064: "FAQ 질문/답변 등록 작업을 완료 했습니다.",
    i20065: "FAQ 등록 작업중",
    i20066: "FAQ 질문/답변 등록 작업을 진행중 입니다.",
    i20067: "FAQ 삭제 완료",
    i20068: "FAQ 질문/답변 삭제를 완료 했습니다.",
    i20069: "FAQ",
    i20070: "등록된 FAQ가 없습니다.",
    i20071: "FAQ 수정 완료",
    i20072: "FAQ 질문/답변 수정을 완료 했습니다.",
    i20073: "FAQ 등록 성공",
    i20074: "FAQ 등록을 완료 했습니다.",
    i20075: "챗봇 종류 및 주요기능",
    i20076: "{name} 설정을 저장했습니다.",
    i20077: "준비중",
    i20078: "준비중인 챗봇 입니다.",
    i20079: "FAQ 등록을 통해 이용할 수 있습니다.",
    i20080: "등록중",
    i20081: "등록 작업이 진행중 입니다.",
    i20082: "작업중",
    i20083: "FAQ 등록 작업이 진행중 입니다.",
    i20084: "작업등록",
    i20085: "FAQ 등록 작업이 완료 되었습니다.",
    i20086: "엑셀로 작성해서 FAQ 업로드하기",
    i20087: "질문/답변 형태로 입력 후 업로드",
    i20088: "반품/교환 주소 등록",
    i20089: "우편번호",
    i20090: "우편번호를 입력하세요",
    i20091: "우편번호",
    i20092: "주소",
    i20093: "주소를 입력하세요.",
    i20095: "상세 주소",
    i20096: "상세 주소를 입력하세요.",
    i20097: "반품 주소의 아파트 이름, 건물 이름, 층",
    i20098: "수령인",
    i20099: "수령인을 입력하세요.",
    i20100: "수령인의 이름",
    i20101: "연락처",
    i20102: "연락처를 입력하세요.",
    i20103: "수령인의 연락처",
    i20104: "종료",
    i20105: "반품/교환 주소 등록",
    i20106: "반품/교환 주소 등록이 완료 되었습니다.",
    i20107: "추가 정보",
    i20108: "필요한 추가 정보를 입력하세요.",
    i20109: "중복 연동",
    i20110: "기존 연동된 정보를 해제하시면 이용 가능합니다.",
    i20111: "연동중",
    i20112: "이미 사용중인 도메인 입니다. 연동을 먼저 해제해 주세요.",
    i20113: "도메인",
    i20114: "상점키",
    i20115: "연동해제",
    i20116: "연동하기",
    i20117: "해제",
    i20118: "연동 정보를 해제하시겠습니까?",
    i20119: "FAQ 카테고리 등록",
    i20120: "카테고리",
    i20121: "카테고리 이름",
    i20122: "FAQ를 구분할 카테고리를 입력하세요.",
    i20123: "카테고리 정보가 없습니다.",
    i20124: "등록 완료",
    i20125: "수정 완료",
    i20126: "삭제 완료",
    i20127: "답변 정보가 없습니다.",
    i20128: "질문 정보가 없습니다.",
    i20129: "카테고리 수정",
    i20130: "수정",
    i20131: "카테고리 삭제",
    i20132: "최소 한 개의 카테고리가 있어야 합니다.",
    i20133: "삭제시 하위 정보까지 모두 삭제됩니다.",
    i20134: "삭제 하시겠습니까?",
    i20135: "등록된 카테고리가 없습니다.",
    i20136: "FAQ 수정",
    i20137: "질문",
    i20138: "답변",
    i20139: "FAQ 삭제",
    i20140: "파일을 먼저 등록해 주세요.",
    i20141: "샘플을 확인하신 후 양식에 맞게 파일을 업로드 해주세요",
    i20142: "파일 등록",
    i20143: "샘플 다운로드",
    i20144: "파일 업로드",
    i20145: "이곳을 클릭하여 파일을 등록하세요",
    i20146: "카테고리를 먼저 생성해 주세요.",
    i20147: "템플릿 변경",
    i20148: "템플릿을 설정했습니다",
    i20149: "1. 도메인 정보 확인",
    i20150: "\"쇼핑몰구축 > 쇼핑몰 기본정보 설정 > 쇼핑몰 도메인 관리\" 탭으로 가셔서 도메인 정보를 확인 할 수 있습니다.",
    i20151: "도메인",
    i20152: "도메인 정보 입력시 http:// 나 https:// 까지 포함시킨 정보를 입력해 주셔야 합니다.",
    i20153: "쇼핑몰 도메인 관리 탭에서 스크롤을 밑으로 내리시면, 위 사진과 같은 도메인을 확인 할 수 있습니다. 초기 쇼핑몰 확인용 멀티도메인 혹은 주도메인을 정보를 확인하여 스크립트 설치 시 도메인 입력창에 입력해 주시면 됩니다.",
    i20154: "2. 상점키 정보 확인",
    i20155: "\"쇼핑몰구축 > 쇼핑몰 관리기능 설정 > 오픈 API\" 탭으로 가셔서 상점키 정보를 확인 할 수 있습니다.",
    i20156: "업체명에 \"포티투마루\"를 검색하시고 추가 버튼을 눌러 줍니다.",
    i20157: "최소한으로 주문, 상품, 회원, 게리판/리뷰 조회권한이 필요합니다.",
    i20158: "권한이 없는 경우, 정상적인 스윙챗 이용이 안될 수 있습니다.",
    i20159: "추가를 완료 하시면 주문, 상품, 회원, 게리판/리뷰에 대한 권한을 클릭하고 신청 버튼을 눌러 상점키 권한을 저장하세요. 그리고 저장된 상점키를 확인하여, 스크립트 설치시 상점키 정보를 입력하세요.",
    i20160: "3. 스크립트 설치",
    i20161: "메이크샵과 연동하기 위해선 도메인 정보와 상점키 정보가 필요 합니다.",
    i20162: "도메인과 상점키 정보를 입력후 연동하기 버튼을 누르면 스크립트를 복사할 수 있습니다.",
    i20163: "도메인과 상점키 정보는 위 내용을 통해 찾아볼 수 있습니다.",
    i20164: "상점키 권한",
    i20165: "왼쪽의 메뉴바에서 \"상단/기본 상단\" 을 클릭합니다. 빨간색 박스의 위치에 복사한 스크립트를 붙여넣습니다. 저장 버튼을 누르시면 스윙챗 버튼을 확인 하실 수 있습니다.",
    i20166: "1. 카페24 앱 설치",
    i20167: "준비중",
    i20168: "카페24 앱스토어에서 '스윙챗'을 검색 후 '설치하기' 버튼 클릭을 클릭합니다.",
    i20169: "2.1 채널 생성",
    i20170: "채널 생성",
    i20171: "카페24와 연동하기 위해선 최소 하나의 채널은 생성해야 합니다.",
    i20172: "하나의 채널에서 동시에 여러 쇼핑몰과 연동할 수 없습니다.",
    i20173: "카페24 계정당 쇼핑몰 하나만 연동이 가능합니다.",
    i20174: "쇼핑몰이 아닌 다른 종류의 채널을 생성시, 기능 제한이 있을 수 있습니다.",
    i20175: "언제든 채널의 종류는 변경할 수 있습니다.",
    i20176: "채널의 이름과 설명을 입력하시고, 챗봇종류 중 쇼핑몰을 선택해주신 후 저장 버튼을 눌러주세요. 생성된 채널이 있으면, \"채널 선택 화면으로 돌아가기\" 버튼을 통해 선택 화면에서 선택을 해주세요.",
    i20177: "2.2 채널 선택",
    i20178: "채널 선택",
    i20179: "이미 연동된 채널이 있으신 경우, 연동된 채널 정보도 노출 됩니다.",
    i20180: "연동시킬 스윙챗 채널을 선택해 주세요. 없으시다면 '채널 생성' 버튼을 통해 새로운 채널을 생성해 주세요.",
    i20181: "3. 스크립트 설치",
    i20182: "선택한 채널에서 스크립트 설치 버튼을 통해 스크립트를 설치 합니다.",
    i20183: "스크립트 삭제",
    i20184: "스크립트 삭제 시, 카페24 쇼핑몰에서 스윙챗 버튼이 사라집니다.",
    i20185: "스크립트는 언제든 다시 설치가 가능 합니다.",
    i20186: "설치 완료 페이지를 확인 하셨으면, 설치된 챗봇 버튼을 확인해 보세요.",
    i20187: "처음 연동을 하시는 경우 \"연동 버튼\"을 눌러서 채널을 생성 및 채널 목록 페이지로 넘어갈 수 있습니다.",
    i20188: "연동하기",
    i20189: "연동하기 페이지의 경우, 상황에 따라 바로 채널 생성 및 채널 목록 등 으로 넘어갈 수 있습니다.",
    i20190: "입력 힌트",
    i20191: "입력창에 노출되는 텍스트를 표현해보세요",
    i20192: "이미지 둥글게",
    i20193: "카페24 앱스토어",
    i20194: "동영상 가이드",
    i20195: "챗봇 이름",
    i20196: "챗봇 설명",
    i20197: "웰컴 메시지",


    i30001: "채널 조회 중 오류가 발생했습니다.",
    i30002: "개설된 채널이 없습니다. 새로운 상담 채널을 만들어 보세요.",
    i30003: "신규 채널 생성",
    i30004: "채널 이름",
    i30005: "채널 설명",
    i30006: "취소",
    i30007: "등록",
    i30008: "채널 생성",
    i30009: "채널 이름은 중복될 수 없습니다.",
    i30010: "필수 입력 항목입니다.",
    i30011: "채널이 생성되었습니다.",
    i30012: "시스템에서 오류가 발생했습니다.",
    i30013: "이미 사용중인 이름입니다.",
    i30014: "계정 정보 조회 중 오류가 발생했습니다.",
    i30015: "채널 조회에 실패했습니다.",
    i30016: "설정",
    i30017: "채팅상담",
    i30018: "고객관리",
    i30019: "메시지",
    i30020: "외부 서비스 연동",
    i30021: "챗봇 설정",
    i30022: "템플릿 설정",
    i30023: "챗봇 이름과 웰컴메시지 설정을 통해 챗봇을 효과적으로 소개해보세요.\n  * 고객이 보게 될 챗봇 이름을 입력하세요. (최대 30자) \n  * 고객이 챗봇을 시작할 때 처음 보게 될 메시지를 입력하세요. \n채팅상담 운영 시간과 공지 내용 등을 함께 넣어주시면 좋습니다!",
    i30024: "챗봇 이름을 입력하세요",
    i30025: "웰컴 메시지를 입력하세요",
    i30026: "저장",
    i30027: "챗봇 기본 설정",
    i30028: "필수 입력값입니다.",
    i30029: "적용할 챗봇 템플릿을 선택해 주세요",
    i30030: "챗봇에는 의도분류, 개체명, 시나리오 등 여러가지 설정값이 존재하지만 \n미리 준비된 템플릿을 사용하시면 간편하게 설정하실 수 있습니다.",
    i30031: "템플릿 사용 안함",
    i30032: "병원 안내 및 예약",
    i30033: "FAQ 챗봇",
    i30034: "템플릿을 선택해 주세요.",
    i30035: "고객 정보 설정",
    i30036: "고객 관리 데이터 설정",
    i30037: "수집할 고객 정보를 설정 합니다.",
    i30038: "수집 항목 추가",
    i30039: "현재 해당 페이지를 보여드릴 수 없습니다.",
    i30040: "타입",
    i30041: "노출",
    i30042: "수정",
    i30043: "항목 수정",
    i30044: "항목 이름",
    i30045: "고객에게 이 데이터를 노출합니다.",
    i30046: "고객 데이터가 추가되었습니다.",
    i30047: "수정불가",
    i30048: "On",
    i30049: "Off",
    i30050: "저장",
    i30051: "`{name}`은(는) 시스템 내부용 항목이기 때문에 사용하실 수 없습니다.",
    i30052: "전체",
    i30053: "세그먼트",
    i30054: "서비스 설정",
    i30055: "고객 정보",
    i30056: "고객 속성 정보 조회 중 오류가 발생했습니다.",
    i30057: "고객 추가",
    i30058: "필터 추가",
    i30059: "필수",
    i30070: "고객 정보가 추가되었습니다.",
    i30071: "고객 삭제",
    i30072: "고객 정보를 삭제하시겠습니까? ",
    i30073: "고객 정보가 수정되었습니다.",
    i30074: "메시지 발송",
    i30075: "태그 달기",
    i30076: "고객 조회 중 오류가 발생했습니다.",
    i30077: "명에게",
    i30078: "고객 세그먼트 조회 중 오류가 발생했습니다.",
    i30079: "세그먼트 생성",
    i30080: "세그먼트 검색",
    i30081: "태깅되었습니다.",
    i30082: "태그 이름",
    i30083: "세그먼트를 저장했습니다.",
    i30084: "전송 완료",
    i30085: "전송 예정",
    i30086: "발신함",
    i30087: "전송 완료",
    i30088: "전송 예정",
    i30089: "제목",
    i30090: "내용",
    i30091: "제목+내용",
    i30092: "메시지 작성",
    i30093: "아웃바운드",
    i30094: "고객에게 전송할 메시지를 작성해 주세요",
    i30095: "수신자를 확인해 주세요",
    i30096: "받는 사람",
    i30097: "전송 시점",
    i30098: "메시지 전송 시점을 설정해 주세요.",
    i30099: "전송 방법",
    i30100: "메시지 전송 방법을 선택해 주세요.",
    i30101: "개별 선택",
    i30102: "조건 필터",
    i30103: "'제목'과 '메시지'는 필수입니다.",
    i30104: "받는 사람",
    i30105: "즉시 전송",
    i30106: "예약 전송",
    i30107: "이메일",
    i30108: "문자메시지",
    i30109: "전송",
    i30110: "'받는 사람'을 선택해 주세요.",
    i30111: "전송 날짜'를 선택해 주세요.",
    i30112: "전송 방법'을 선택해 주세요.",
    i30113: "상태",
    i30114: "발신일",
    i30115: "작성일",
    i30116: "보내기",
    i30117: "이미 사용중인 채널입니다.",
    i30118: "항목 설명",
    i30119: "작성중",
    i30120: "메시지 삭제",
    i30121: "이미 사용중인 세그먼트입니다. ",
    i30122: "이미 사용중인 항목 키 입니다.",
    i30123: "세그먼트 삭제",
    i30124: "태그",
    i30125: "고객 수정",
    i30126: "날짜",
    i30127: "등록된 고객이 없습니다.",
    i30128: "등록된 메시지가 없습니다.",
    i30129: "전화번호",
    i30130: "퀵스타트",
    i30131: "챗봇 만들기",
    i30132: "챗봇 스타일 설정",
    i30133: "테스트",
    i30134: "테스트 링크 열기",
    i30135: "퀵스타트 정보 조회에 실패했습니다.",
    i30136: "챗봇을 위한 기본 설정입니다.",
    i30137: "현재 퀵스타트 이용이 불가능합니다.",
    i30138: "이전 단계가 모두 완료되어야 합니다.",
    i30139: "템플릿",
    i30140: "채널 로고",
    i30141: "데이터를 불러 수 없습니다.",
    i30142: "더보기",
    i30143: "접기",
    i30144: "팔로우",
    i30145: "최근대화",
    i30146: "{consultant} 상담사와 {duration} 동안 {category} 카테고리로 상담",
    i30147: "타임라인",
    i30148: "챗봇 대화",
    i30149: "채팅상담 연결",
    i30150: "챗봇 템플릿 설정",
    i30151: "50% 이상의 고객 질의를 처리해줄 챗봇을 설정합니다.",
    i30152: "튜토리얼로 빠르게 고객을 만나세요.",
    i30153: "챗봇으로 처리가 힘든 고객의 질의를 처리할 채팅상담 메신저를 설정합니다.",
    i30154: "설정한 내용을 테스트 합니다. 아래 링크를 통하여 챗봇과 채팅상담 메신저를 확인하세요.",
    i30155: "필수 설정이 끝났습니다. '채팅상담 메신저 설정'이 가능합니다.",
    i30156: "필수 설정이 끝났습니다. 설정된 챗봇을 사용해보고 싶으시면 '테스트 링크 열기' 버튼을 눌러주세요.",
    i30157: "나가기",
    i30158: "튜토리얼을 종료합니다.",
    i30159: "저장안된 항목",
    i30160: "저장안된 항목이 있습니다. 편집중인 항목을 삭제하시겠습니까?",
    i30161: "넘기기",
    i30162: "'템플릿 설정' 과 'FAQ 등록' 중에서 하나 이상은 설정 해야 합니다.",
    i30163: "필드",
    i30164: "최초 접속 시간",
    i30165: "게스트",
    i30166: "회원",
    i30167: "최근 접속 시간",
    i30168: "총 접속 횟수",
    i30169: "문자열",
    i30170: "숫자",
    i30171: "날짜",
    i30172: "기본 프로필 정보 입력",
    i30173: "최초 접속",
    i30174: "대화 이력이 없습니다.",
    i30175: "활동 이력이 없습니다.",
    i30176: "AND 조건 추가",
    i30177: "검색어를 입력하세요.",
    i30178: "다음과 일치",
    i30179: "다음과 일치하지 않음",
    i30180: "다음을 포함",
    i30181: "다음을 포함하지 않음",
    i30182: "값이 있는",
    i30183: "값이 없는",
    i30184: "~ 이상",
    i30185: "~ 이하",
    i30186: "최근 ~일 이내",
    i30187: "~일 이상 경과",
    i30188: "~일 이후",
    i30189: "해당 날짜까지",
    i30190: "해당 날짜부터",
    i30191: "해당 날짜에",
    i30192: "지금으로부터",
    i30193: "달력에서 선택",
    i30194: "일",
    i30195: "적용",
    i30196: "{value}와(과) 일치",
    i30197: "{value}와(과) 일치하지 않음",
    i30198: "{value} 을(를) 포함",
    i30199: "{value} 을(를) 포함하지 않음",
    i30200: "{value} 이상",
    i30201: "{value} 이하",
    i30202: "최근 {value}일 이내",
    i30203: "{value}일 이상 경과",
    i30204: "{value}일 경과",
    i30205: "{value}까지",
    i30206: "{value}부터",
    i30207: "{value}에",
    i30208: "세그먼트 저장",
    i30209: "세그먼트 이름",
    i30210: "또는",
    i30211: "세그먼트 수정",
    i30212: "그리고",
    i30213: "저장하신 메세지를 차지 못했습니다.",
    i30214: "메시지 조회 중 오류가 발생했습니다.",
    i30215: "메시지 조회 중 오류가 발생했습니다.",
    i30216: "또는 {value}개의 항목",
    i30217: "검색어를 입력하시고 엔터키를 눌러주세요.",
    i30218: "주요 기능 설정",
    i30219: "상품 입고일",
    i30220: "'재입고 문의' 기능이 비활성화 상태입니다.",
    i30221: "상품명",
    i30222: "입고일",
    i30223: "상품이미지",
    i30224: "이미 연동된 채널입니다.",
    i30225: "입고일 수정",
    i30226: "입고일이 수정되었습니다.",
    i30227: "입고일 수정 중 오류가 발생했습니다.",
    i30228: "등록된 상품이 없습니다.",
    i30229: "입고일이 저장되었습니다.",
    i30230: "검색어를 입력하세요.",
    i30231: "시스템 아이디",
    i30232: "전체 메시지 전송",
    i30233: "연동 관리",
    i30234: "커머스",
    i30235: "커머스 연동 가이드",
    i30236: "카페24",
    i30237: "카페24를 통해 챗봇 아이콘을 설치하시면 고객 정보 및 주문 연동이 지원됩니다.",
    i30238: "카페24 연동 가이드",
    i30239: "메이크샵",
    i30240: "메이크샵를 통해 챗봇 아이콘을 설치하시면 고객 정보 및 주문 연동이 지원됩니다.",
    i30241: "메이크샵 연동 가이드",
    i30242: "스윙챗 계정이 필요합니다. 계정이 없다면 회원 가입을 진행해주세요.",
    i30243: "연동시킬 {channel}을 선택해 주세요. 없으시다면 '{channel} 생성' 버튼을 통해 새로운 {channel}을 생성해 주세요.",
    i30244: "{channel}의 이름과 설명을 입력하시고, 챗봇종류 중 쇼핑몰을 선택해주신 후 저장 버튼을 눌러주세요. (다른 챗봇 종류 선택시 기능에 제한이 생깁니다.)",
    i30245: "챗봇 아이콘을 설치합니다. '스크립트 설치' 버튼을 눌러주세요.",
    i30246: "스윙챗이 설치되었습니다. 사이트에서 챗봇 아이콘을 확인하시고, 스윙챗 관리자 화면에서 기타 설정을 진행해 주세요.",
    i30247: "외부 서비스 기반의 커머스 사이트에 챗봇 아이콘을 설치합니다",
    i30248: "복사 되었습니다.",
    i30249: "메이크샵을 통해 챗봇 아이콘을 설치하시면 고객 정보 및 주문 연동이 지원됩니다.",
    i30250: "상단의 '개별 디자인' 을 클릭하신 후 '디자인 편집하기' 에 들어갑니다.",
    i30251: "도메인과 Shop Key를 등록 후, 챗봇 아이콘 설치 스크립트를 복사합니다.",
    i30252: "왼쪽의 메뉴바에서 '상단/기본 상단' 을 클릭합니다.",
    i30253: "빨간색 박스의 위치에 복사한 스크립트를 붙여넣습니다.",
    i30254: "저장 후 사이트에서 챗봇 아이콘을 확인합니다.",
    i30255: "메이크샵 관리자 페이지로 이동합니다.",
    i30256: "브라우저",
    i30257: "디바이스",
    i30258: "OS",
    i30259: "태그를 입력하시고 엔터키를 눌러주세요.",
    i30260: "고객 데이터가 수정되었습니다.",
    i30261: "고객 데이터 수정 중 오류가 발생했습니다.",
    i30262: "삭제되었습니다.",
    i30263: "입고일 등록",
    i30264: "상품 이름을 입력하세요.",
    i30265: "상품이 없습니다.",
    i30266: "상품 조회중 오류가 발생했습니다.",
    i30267: "링크",
    i30268: "입고일을 입력하세요.",
    i30269: "{attribute} 을(를) 수집데이터 항목에서 삭제하시겠습니까?",
    i30270: "수집 항목 삭제",
    i30271: "고객 정보 다운로드",
    i30272: "최대",
    i30273: "고객 데이터는 1 ~ 10000 범위 내에서 다운로드 가능합니다.",
    i30274: "고객정보 다운로드 중 오류가 발생했습니다. ",
    i30275: "다운로드",
    i30276: "고객 이름",
    i30277: "챗봇 설명을 입력하세요.",
    i30278: "클립보드로 복사",
    i30279: "게시판 관리",
    i30280: "채팅상담 메신저에서 게시판 질문을 관리할 수 있습니다.\n- 새 글, 새 댓글 작성 시 알림을 받을 수 있습니다.\n- 상담사 메신저에서 바로 게시물이나 댓글을 작성할 수 있습니다.",
    i30281: "연동",
    i30282: "카페24",
    i30283: "메이크샵",
    i30284: "종류",
    i30285: "게시판 이름",
    i30286: "카페24 게시판",
    i30287: "메이크샵 게시판",
    i30288: "멀티쇼핑몰 번호 (shop_no)",
    i30289: "쇼핑몰 아이디 (mall_id)",
    i30290: "게시판",
    i30291: "필수입니다.",
    i30292: "게시판 알림",
    i30293: "게시판 삭제",
    i30294: "게시판을 삭제합니다. 게시판과 채팅상담 메신저와의 연동이 해제됩니다.",
    i30295: "게시판 추가",
    i30296: "외부 서비스와 챗봇을 연동합니다. 하나의 챗봇은 두 개 이상의 외부 서비스와 연동할 수 없으며 추가 연동시 이전 연동은 해제됩니다.",
    i30297: "이미 연동된 게시판입니다.",
    i30298: "해당 게시판은 현재 사용이 불가능한 상태입니다. 게시판이 활성화 상태인지 확인해 주세요.",
    i30299: "해당 쇼핑몰을 조회할 수 없습니다. 입력값을 확인해 주세요.",
    i30300: "스윙챗이 설치된 쇼핑몰에 한해서만 연동이 가능합니다.",
    i30301: "챗봇 연동 정보를 조회하지 못했습니다.",
    i30302: "채널은 두 개 이상의 서비스와 연동할 수 없습니다.",
    i30303: "연동 해제 방법은 해당 서비스의 '연동 가이드'에서 확인하실 수 있습니다.",
    i30304: "챗봇 인덱스 설정",
    i30305: "챗봇 첫화면(인덱스)의 구성요소를 편집하고 순서를 설정할 수 있습니다. (📢 각 아이템의 이미지를 드래그하면 순서를 변경할 수 있습니다.)",
    i30306: "챗봇 상담",
    i30307: "새로운 대화의 시작 및 카카오톡, 네이버톡톡, 페북, 인스타 등 메신저 순서를 설정합니다.",
    i30308: "진행중인 상담",
    i30309: "예전 채팅 목록을 확인하고 대화를 이어갈 수 있습니다.",
    i30310: "카드뷰",
    i30311: "유튜브, 이벤트 페이지, 블로그 포스팅, 상품 페이지 등을 홍보할 수 있습니다.",
    i30312: "자주묻는 질문",
    i30313: "자주묻는 질문을 제공해 궁금한 점을 고객 스스로 해결할 수 있습니다.",
    i30314: "등록된 카드뷰가 없습니다.",
    i30315: "다음",
    i30316: "이미지",
    i30317: "노출 기간",
    i30318: "기간",
    i30319: "이미지가 없습니다. 이미지를 입력하세요.",
    i30320: "클릭시 이동할 링크",
    i30321: "변경",
    i30322: "제목과 이미지는 필수입니다.",
    i30323: "주소입력",
    i30324: "내용 가져오기",
    i30325: "입력하신 주소에서 정보를 가져오고 있습니다.",
    i30326: "입력하신 주소를 불러오는 중 문제가 발생했습니다.",
    i30327: "카드뷰 삭제",
    i30328: "주소를 입력해주세요.",
    i30329: "입력하신 주소에서 정보를 가져왔습니다. 확인 후 수정하세요.",
    i30330: "카드뷰에 추가할 URL을 입력하세요.",
    i30331: "항목 키 (영문자만 입력가능합니다.)",
    i30332: "항목의 고유한 아이디 입니다.",
    i30333: "채널을 찾을 수 없습니다.",
    i30334: "최근 ~시간 이내",
    i30335: "최근 {value}시간 이내",
    i30336: "이름, 전화번호, 이메일로 검색해보세요",
    i30337: "유효하지 않은 초대 링크입니다.",
    i30338: "채널 템플릿은 필수입니다.",
    i30339: "* '회원 아이디'가 중복되는 회원은 추가되지 않습니다.",
    i30340: "멤버 아이디: {member_id}",
    i30341: "챗봇 메시지 설정",
    i30342: "챗봇이 답변하는 메시지들을 수정할 수 있습니다.",
    i30343: "텍스트를 입력해 주세요.",
    i30344: "타이틀을 입력해 주세요.",
    i30345: "버튼명 입력",
    i30346: "버튼 추가",
    i30347: "카드 추가",
    i30348: "버튼 저장",
    i30349: "채팅상담 요청 ( 채팅상담 ID: {liveChatId} )",
    i30350: "채팅상담 담당자 변경 ( 채팅상담 ID: {liveChatId} )",
    i30351: "채팅상담 첫 질문 ( 채팅상담 ID: {liveChatId} )",
    i30352: "채팅상담 상태: 응답중 ( 채팅상담 ID: {liveChatId} )",
    i30353: "채팅상담 상태: 완료 ( 채팅상담 ID: {liveChatId} )",
    i30354: "채팅상담 상태: 닫힘 ( 채팅상담 ID: {liveChatId} )",
    i30355: "채팅상담 상태: 만료 ( 채팅상담 ID: {liveChatId} )",
    i30356: "채팅상담 상태: 보류 ( 채팅상담 ID: {liveChatId} )",
    i30357: "채팅상담 상태: 취소 ( 채팅상담 ID: {liveChatId} )",
    i30358: "카테고리: {category}",
    i30359: "담당자: {name}",
    i30360: "없음",
    i30361: "'파일 첨부'",
    i30362: "없음",
    i30363: "첫 질문: {question}",
    i30364: "버튼 메시지",
    i30365: "변경된 설정이 저장되었습니다.",
    i30366: "고객 정보 마스킹 설정",
    i30367: "개인정보 보호를 위해 고객 정보를 마스킹합니다.",
    i30368: "마스킹 설정",
    i30369: "마스킹",
    i30370: "마스킹 포맷",
    i30371: "고객정보 마스킹",
    i30372: "적용 범위",
    i30373: "상세보기",
    i30374: "전체보기",
    i30375: "마스킹 설정이 저장되었습니다.",
    i30376: "미지원",
    i30377: "고객정보 마스킹 설정",
    i30378: "'고객 정보 마스킹' 활성화는 '보안 설정' 화면에서 가능합니다. 이동하시겠습니까?",
    i30379: "고객 정보 다운로드",
    i30380: "사유를 입력하세요.",
    i30381: "관리도구 로그 수집 설정",
    i30382: "스윙챗 이용자의 관리도구 활동 이력을 수집합니다.",
    i30383: "CRM 조회 이력이 수집됩니다.",
    i30384: "권한 그룹 및 멤버의 권한 변경 이력이 수집됩니다.",
    i30385: "관리자 로그 수집 설정이 저장되었습니다.",
    i30386: "고객 정보 다운로드시 '사유'는 필수 입력입니다.",
    i30387: "관리도구 로그 다운로드",
    i30388: "고객 정보 조회 및 수정",
    i30389: "카테고리를 선택하세요.",
    i30390: "파일 다운로드 중",
    i30391: "사유를 입력하세요.",
    i30392: "고객 정보 파일을 준비 중입니다.",
    i30393: "관리자 로그를 파일을 준비 중입니다.",
    i30394: "파일 준비 완료",
    i30395: "선택하세요",
    i30396: "로그 종류",
    i30397: "다운로드 요청",
    i30398: "현재는 early access 기간으로 기본 기능을 무료로 체험할 수 있습니다. 2023년 상반기 유료 플랜을 출시할 예정입니다.",
    i30399: "기본 금칙어 등록",
    i30400: "상담원 입력 메시지 중 금칙어가 포함되어 있을 경우 확인 후 전송(비활성화시 모든 메시지 즉시 전송)",
    i30401: '초기화',
    i30402: '금칙어 초기화',
    i30403: '저장된 모든 금칙어를 삭제합니다.',
    i30404: "욕설 및 비속어를 금칙어로 등록합니다.",






    i40001: "외부 메신저",
    i40002: "카카오톡",
    i40003: "카카오톡을 연동하여 스윙챗에서 한 번에 관리하세요. 알림톡을 통해 마케팅 메시지까지 보낼 수 있습니다.",
    i40004: "카카오톡 연동 활성화",
    i40005: "카카오톡과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 카카오톡에서 챗봇이 동작하지 않습니다.",
    i40006: "시작",
    i40007: "카카오톡 오픈빌더",
    i40008: "카카오톡 오픈빌더에 접속 후 카카오톡 챗봇을 생성합니다.",
    i40009: "스킬 설정",
    i40010: "스킬 탭을 선택하여 웰컴 스킬, 폴백 스킬을 추가합니다.\n웰컴 스킬 URL과 폴백 스킬 URL을 복사하여 붙여넣기 하세요.,",
    i40011: "블럭 설정",
    i40012: "시나리오 탭을 선택하여 웰컴 블록을 선택 후 웰컴 스킬을 설정합니다.",
    i40013: "웰컴 블록과 마찬가지로 폴백 블록을 선택 후 폴백 스킬을 설정합니다.",
    i40014: "채널 연결",
    i40015: "설정 탭을 선택하여 카카오톡 채널을 연결합니다.\n카카오톡 채널은 카카오톡채널 관리자 센터에서 관리하실 수 있습니다.,",
    i40016: "카카오톡채널 관리자 센터",
    i40017: "배포",
    i40018: "배포 탭을 선택하신 후 배포를 진행하시면 카카오톡 채널에서 챗봇 동작을 확인하실 수 있습니다.",
    i40019: "카카오톡 챗봇",
    i40020: "웰컴 스킬 URL",
    i40021: "폴백 스킬 URL",
    i40022: "카카오톡 챗봇 연동 가이드",
    i40023: "네이버톡톡",
    i40024: "네이버톡톡을 연동하여 스윙챗에서 한 번에 관리하세요.",
    i40025: "네이버톡톡 연동",
    i40026: "네이버톡톡 연동 활성화",
    i40027: "네이버톡톡과 챗봇을 연동하려면 체크하세요",
    i40028: "이벤트 받을 URL",
    i40029: "페이스북 메신저",
    i40030: "페이스북 메신저를 연동하여 스윙챗에서 한 번에 관리하세요.",
    i40031: "페이스북 연동",
    i40032: "페이스북 연동 활성화",
    i40033: "인스타그램",
    i40034: "인스타그램을 연동하여 스윙챗에서 한 번에 관리하세요.",
    i40035: "인스타그램 연동",
    i40036: "인스타그램 연동 활성화",
    i40037: "페이스북과 챗봇을 연동하려면 체크하세요",
    i40038: "인스타그램과 챗봇을 연동하려면 체크하세요",
    i40039: "메시지에서 HTML 태그 제거(성능 저하)",
    i40041: "최대 10000 건까지만 다운로드 됩니다.",
    i40042: "필터를 활용해서 일부 고객을 선택할 수 있습니다.",
    i40043: "상품 입고일 삭제",
    i40044: "상품 입고일을 삭제합니다. ",
    i40045: "{consultant} 상담사와 {duration} 동안 상담",
    i40046: "이메일 형식에 맞지 않습니다.",
    i40047: "전화번호 형식에 맞지 않습니다.",
    i40048: "동일한 이름과 전화번호를 가진 유저가 존재합니다. 수정하시면 기존 유저 정보에 현재 유저 정보를 덮어씌웁니다.\n\n 수정하시겠습니까?",
    i40049: "같은 이름과 전화번호를 가진 유저는 생성하실 수 없습니다.",
    i40050: "고객 정보 병합",
    i40051: "올바른 카카오톡 Link URL을 입력하세요. 예) http://pf.kakao.com/xxxxxx",
    i40052: "올바른 페이스북 Link URL을 입력하세요. 예) https://m.me/page_name",
    i40053: "올바른 인스타그램 Link URL을 입력하세요. 예) https://www.instagram.com/instagram_account",
    i40054: "올바른 네이버톡톡 Link URL을 입력하세요. 예) http://naver.me/xxxxxx",
    i40055: "네이버톡톡 링크를 가져오려면 QR코드 이미지(PNG)를 여기에 업로드해주세요.\n\n[QR코드 다운로드 방법]\n네이버톡톡 파트너센터 > 설정 > 톡톡 배너/QR코드 다운 > 우측 하단 '다운받기' > PNG로 저장",
    i40056: "챗봇 주요 기능",
    i40057: "챗봇 주요 기능 설정하기",
    i40058: "채팅 상담 설정하기",
    i40059: "고객 관리 설정하기",
    i40060: "메시지 설정하기",
    i40061: "채팅 상담 주요기능",
    i40062: "고객 관리 주요 기능",
    i40063: "메시지 주요 기능",
    i40064: "검색",
    i40065: "라인",
    i40066: "라인을 연동하여 스윙챗에서 한 번에 관리하세요.",
    i40067: "라인 연동",
    i40068: "라인 연동 활성화",
    i40069: "라인과 챗봇을 연동하려면 체크하세요. 체크를 해제하면 라인에서 챗봇이 동작하지 않습니다.",
    i40070: "라인 Webhook Url",
    i40071: "Link URL을 설정하면 WebChat에 메신저로 바로가기 아이콘을 노출할 수 있습니다.",
    i40072: "올바른 라인 Link URL을 입력하세요. 예) https://lin.ee/",
    i40073: "라인 연동 방법",
    i40074: "1. provider를 생성한 뒤 선택합니다.\n2. channel생성을 시작하여 messaging api를 선택합니다.",
    i40075: "Webhook 설정",
    i40076: "채널에 들어가 Messaging API 탭을 선택하고 WebHook Url 주소를 추가합니다.\n라인 Webhook Url을 복사 붙여넣기 하세요.",
    i40077: "채널 답변 특성 설정",
    i40078: "Line Official Account features의 Auto-reply messages와 Greeting messages를 Disabled로 바꿔주어야합니다.\nAuto-reply messages의 Edit 버튼을 클릭하여 설정창으로 들어갑니다.",
    i40079: "표시된 것과 같이 설정해줍니다.",
    i40080: "Channel access token 생성",
    i40081: "Channel access token을 생성해줍니다.",
    i40082: "Link URL 설정 방법",
    i40083: "클릭해서 Link URL가이드를 확인할 수 있어요.",
    i40084: "접속 경로: 페이지 접속 -> 페이지 설정 -> 메시지 탭 클릭",
    i40085: "인스타그램은 채팅으로 바로가기 URL을 제공하지 않습니다.\n인스타그램 계정 URL을 입력해주세요.\n\nex) https://www.instagram.com/계정명",
    i40086: "1. 채널 관리자 센터 접속\n",
    i40087: "2. 채널 홍보 탭 클릭\n3. 채팅 URL 복사",
    i40088: "Link URL 가이드",
    i40089: "이벤트 변경 버튼을 클릭합니다.",
    i40090: "네이버톡톡 연동 가이드\n",
    i40091: "1. 네이버톡톡 파트너센터 접속\n",
    i40092: "2. 톡톡 계정 생성 후 계정 관리 페이지 접속\n3. 개발자도구 탭의 챗봇API 설정 클릭",
    i40093: "네이버톡톡 연동 설정",
    i40094: "수신 이벤트 설정",
    i40095: "1. Webhook URL 설정\n2. Authorization 생성 후 스윙챗에 등록\n",

    i50001: "발신번호",
    i50002: "알림톡",
    i50003: "LMS",
    i50004: "SMS",
    i50005: "타입",
    i50006: "전송시간",
    i50007: "등록시간",
    i50008: "가격",
    i50009: "조회",
    i50010: "메시지 전송 내역이 없습니다.",
    i50011: "총액",

    i50012: "고객 정보 입력",
    i50013: "파일이름.xlsx",
    i50014: "\"{name}\" - {size}B",
    i50015: "* '이름' 과 '전화번호' 가 중복되는 비회원은 추가되지 않습니다.",
    i50016: "* 한 번에 최대 8000건까지 지원합니다.",

    i50017: "고객 업로드 중",
    i50018: "템플릿 다운로드",

    i50019: "최대 업로드 제한 초과",
    i50020: "유효하지 않은 컬럼 명",
    i50021: "중복 데이터",
    i50022: "name 은 필수 컬럼 입니다.",
    i50023: "읽을 수 없는 파일 형식입니다.",
    i50024: "데이터 타입 오류.",

    i50025: "최대 유저 수: {row_limit}",
    i50026: "컬럼 명: {col_name}",
    i50027: "셀 번호: {cell_num}",
    i50028: "셀 번호: {cell_num}, 컬럼 명: {col_name}",

    i50029: "채널 삭제",
    i50030: "채널을 삭제합니다. 삭제된 채널은 복구할 수 없으며 챗봇 및 채팅상담 사용이 제한됩니다.",
    i50031: "삭제",
    i50032: "\"{channel}\" 채널을 삭제합니다.",

    i50033: "유료 플랜을 사용중인 채널은 삭제할 수 없습니다.",
    i50034: "문자 충전 잔액이 남아있는 채널은 삭제할 수 없습니다.",

    i50035: "카카오 상담톡",
    i50036: "카카오톡과 연동하여 스윙챗으로 고객과 실시간 상담 서비스를 할 수 있습니다.",
    i50037: "카카오 상담톡 연동",
    i50038: "카카오 상담톡 연동 활성화",
    i50039: "카카오 검색용 아이디 설정 방법",
    i50040: "카카오 비지니스 채널로 로그인 후 대시보드 페이지 열기",
    i50041: "상담톡 연결을 원하는 채널 상세로 들어가 관리 > 상세설정에 '검색용아이디' 를 복사하기",
    i50042: "상단의 카카오 검색용 아이디에 등록",
    i50043: "카카오 검색용 아이디 가이드",
    i50044: "카카오 검색용 아이디를 등록하여야만 상담톡 이용이 가능합니다.",
    i50045: "카카오 채널 검색용 아이디",
    i50046: "심사 중",
    i50047: "심사 실패",
    i50048: "연동완료",
    i50049: "상담톡 심사 통과까지 1~3일 정도의 기간이 소요됩니다",
    i50050: "카카오톡에 상담톡을 연동하려면 체크하세요. 체크를 해제하면 카카오톡에서 상담톡이 동작하지 않습니다.",
    i50051: "카카오톡에 상담톡 신청 완료했습니다. 심사는 1~2일 정도 소요됩니다.",
    i50052: "검색용아이디가 다른 채널과 중복됩니다",
    i50053: "카카오 비지니스채널 - 방금 등록한 채널 관리자센터 - 관리 - 상세설정 - 채널채팅 상담원 연결버튼 OFF 로 변경하기",

    i50054: "카카오 알림톡",
    i50055: "카카오 알림톡 연동 가이드",

    i50056: "연동",
    i50057: "연동 완료",
    i50058: "해제",
    i50059: "카카오 검색용 아이디",
    i50060: "카카오 알림톡을 연동하면 카카오 알림톡은 마케팅 메시지 전송시 문자보다 우선적으로 보내집니다. 만약 알림톡 발송에 실패하거나 사용자가 차단한 경우, 자동적으로 문자(SMS, LMS)로 대체 발송됩니다.\n아래가이드를 따라 설정해 주시고 혹시 어려운 점이 있으면 스윙챗으로 문의해주세요.",
    i50061: "1. 카카오 비지니스 접속하기",
    i50062: "https://business.kakao.com",
    i50063: "2. 카카오 비지니스 검색용 아이디 가져오기\n카카오 비지니스 채널 대시보드 상담톡 연동할 채널의 상세 페이지로 들어간 다음, 관리 > 상세설정의 \"검색용 아이디\"를 복사하고, 스윙챗의 입력란에 복사해온 카카오 채널의 \"검색용 아이디\" 붙여넣기",
    i50064: "3. 카카오 비지니스 채널 설정하기\n스윙챗에 방금 등록한 카카오 비지니스 채널 프로필 설정에서 채널 공개를 ON으로 변경하기",
    i50065: "4. 스윙챗 설정하기\n담당자 휴대폰 번호와 카테고리를 설정한 뒤 카카오톡으로 수신되는 인증번호 입력하기",
    i50066: "(4.1 심사과정이 필요한 경우 \"심사 중\"으로 나타나며 이 과정은 영업일 기준 최대 2일 소요됩니다.)",
    i50067: "5. 정상적으로 연동이 완료되면 \"연동 완료\"으로 나타나며 고객에게 마케팅 메시지를 전송할 때 알림톡이 문자보다 우선적으로 보내집니다.",
    i50068: "알림톡을 사용하려면 \"카카오 비지니스\"에서 카카오톡 채널을 등록하고 \"비지니스 인증\"을 꼭 받으셔야 합니다.\n카카오톡 채널 프로필에서 채널 공개를 ON으로 변경하세요.\n카카오 채널 연동 후 검수 완료까지 1~3일정도 소요됩니다.",
    i50069: "연동 가이드",
    i50070: "를 참고해서 카카오 비지니스 채널에 접속하여 ",
    i50071: "검색용 아이디와 휴대폰번호를 입력하고 카테고리를 선택",
    i50072: "해주세요.",

    i50073: "클립보드에 복사되었습니다.",
    i50074: "챗봇 삭제가 완료되었습니다.",
    i50075: "웰컴 스킬 URL",
    i50076: "폴백 스킬 URL",
    i50077: "Link URL",
    i50078: "기능 소개",
    i50079: "한번만 설정하면 카카오톡 챗봇과 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요. 연동 한방에 챗봇과 채팅상담이 모두 이뤄집니다. 아래 가이드를 따라 설정해 주시고, 혹시 어려운 점이 있으면 스윙챗으로 문의해주세요.",
    i50080: "https://center-pf.kakao.com\n",
    i50081: "Notice !",
    i50082: "스윙챗은 하나의 카카오톡 챗봇과 1:1로 연동됩니다.",
    i50083: "챗봇 등록이 완료되었습니다.",
    i50084: "Link URL이 등록되었습니다.",
    i50085: "를 참고해서 카카오톡 오픈빌더에 접속하여 ",
    i50086: "스윙챗의 웰컴스킬 URL과 폴백 스킬 URL을 스킬설정에 추가",
    i50087: "하시고 ",
    i50088: "블록설정에서 스킬을 설정",
    i50089: "한 다음 채널을 연결, 마지막으로 배포해주세요.",
    i50090: "다음",
    i50091: "추가로 카카오톡 챗봇에 Link URL을 설정해서 WebChat에서 '메신저로 바로가기' 아이콘을 노출할 수 있습니다.\n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50092: "Link URL 설정가이드",
    i50093: "https://center-pf.kakao.com",
    i50094: "1. 카카오비지니스 채널 관리자센터 접속",
    i50095: "2. 채널 홍보 탭 클릭",
    i50096: "3. 채널 URL 복사",
    i50097: "나중에",
    i50098: "완료",
    i50099: "심사 완료",
    i50100: "심사 실패",
    i50101: "계정 승인 대기중",
    i50102: "상태 변경 대기중",
    i50103: "심사 진행중",
    i50104: "심사 현황",
    i50105: "카카오 검색용 아이디",
    i50106: "카카오 상담톡과 스윙챗의 연동은 카카오톡에서 채팅방을 통한 상담이 스윙챗으로 이어지도록 합니다. \n카카오톡 챗봇을 연동하는 경우에는 카카오톡에서 채팅상담 뿐 아니라 챗봇까지 연동되지만, 채팅상담을 진행할 때는 고객에게 카카오톡 내 Webchat으로 나타납니다. \n카카오톡 상담톡은 카카오톡 어플 내에서 채팅상담이 지원되나 카카오의 정책(비용 등)을 따르고 있습니다. 자세한 내용은 카카오 비지니스 채널을 참고해주세요.",
    i50107: "카카오 상담톡 연동 가이드",
    i50108: "카카오 상담톡과 연동에는 인증을 위한 대기시간이 있을 수 있어요.",
    i50109: "상담톡이 신청되었습니다.",
    i50110: "카카오 상담톡 연동",
    i50111: "를 참고해서 카카오 비지니스 채널에 접속하여",
    i50112: "검색용 아이디를 확인하여 입력해주세요.",
    i50113: "검색용 아이디",
    i50114: "카카오 비지니스 채널의 검색용 아이디를 입력하세요",
    i50115: "@를 제외한 아이디만 입력하여 주세요.",
    i50116: "취소",
    i50117: "등록",
    i50118: "Link URL 설정",
    i50119: "추가로 카카오 상담톡에 Link URL을 설정해서 WebChat에서 '메신저로 바로가기' 아이콘을 노출할 수 있습니다. \n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50120: "카카오비지니스 채널 관리자센터 접속",
    i50121: "채널 홍보 탭 클릭",
    i50122: "채널 URL 복사",
    i50123: "페이스북 로그인에 실패하였습니다.",
    i50124: "연동된 페이스북 페이지",
    i50125: "한번만 설정하면 페이스북 메시저와 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요. 연동 한방에 챗봇과 채팅상담이 모두 이루어집니다. 연동하기를 누르고 페이스북 페이지의 안내에 따라 진행하면 연동이 모두 완료됩니다.",
    i50126: "연동 설정",
    i50127: "페이스북 페이지를 선택해주세요.",
    i50128: "확인",
    i50129: "구독 등록에 실패하였습니다",
    i50130: "챗봇 등록이 완료되었습니다.",
    i50131: "연동 설정 중복",
    i50132: "다른 시나리오에서 연동되어있습니다. : {scenario_id}\n다른 시나리오에서 연동을 해제하고 현재 시나리오에서 연동을 진행할까요?",
    i50133: "연동하기",
    i50134: "설정한 페이지로 이동",
    i50135: "페이스북 메신저 연동",
    i50136: "페이스북 연동내역을 가져오는데 실패했습니다.",
    i50137: "마지막 단계에서 권한을 모두 허용해주셔야 합니다.",
    i50138: "Facebook 권한 재설정하기.",
    i50139: "연동할 페이스북 페이지 선택",
    i50140: "취소",
    i50141: "추가로 페이스북 메신저에 Link URL 을 설정해서 WebChat에서 '메신저로 바로가기' 아이콘을 노출할 수 있습니다.\n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50142: "연동된 페이지",
    i50143: "한번만 설정하면 인스타그램과 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요.\n연동 한방에 챗봇과 채팅상담이 모두 이루어집니다.\n연동하기를 누르고 페이스북 페이지의 안내에 따라 진행하시면 연동이 완료됩니다.",
    i50144: "연동 설정",
    i50145: "인스타그램과 연결된 페이스북 페이지를 선택해주세요.",
    i50146: "확인",
    i50147: "구독 등록에 실패하였습니다",
    i50148: "챗봇 등록이 완료되었습니다",
    i50149: "인스타그램 연동",
    i50150: "인스타그램 연동내역을 가져오는데 실패했습니다.",
    i50151: "마지막 단계에서 권한을 모두 허용해주셔야 합니다.",
    i50152: "인스타그램 권한 재설정하기",
    i50153: "연동할 인스타그램 비지니스 계정 선택",
    i50154: "취소",
    i50155: "다음",
    i50156: "추가로 페이스북 메신저에 Link URL 을 설정해서 WebChat에서 '메신저로 바로가기' 아이콘을 노출할 수 있습니다.\n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50157: "https://www.instagram.com/계정명",
    i50158: "인스타그램은 채팅으로 바로가기 URL을 제공하지 않습니다.\n인스타그램 계정 URL을 입력해주세요.",
    i50159: "ex) https://www.instagram.com/계정명",
    i50160: "한번만 설정하면 라인과 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요. 연동 한방에 챗봇과 채팅상담이 모두 이루어집니다.\n아래 가이드를 따라 설정해 주시고 혹시 어려운 점이 있으면 스윙챗으로 문의해주세요.",
    i50161: "스윙챗은 하나의 라인 계정과 1:1로 연동됩니다.",
    i50162: "라인 등록이 완료되었습니다.",
    i50163: "라인 linkURL 등록이 완료되었습니다.",
    i50164: "라인 연동",
    i50165: "를 참고해서 ",
    i50166: "스윙챗의 라인 Webhook Url을 라인의 Webhook Url에 등록",
    i50167: "한 뒤 ",
    i50168: "라인에서 채널 답변 특성을 설정",
    i50169: "한 뒤",
    i50170: "Channel access token과 Channel secret을 아래 빈칸에 입력",
    i50171: "해주세요.",
    i50172: "라인 Webhook Url",
    i50173: "추가로 라인에 Link URL 을 설정해서 WebChat에서 '메신저 바로가기' 아이콘을 노출할 수 있습니다.\n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50174: "라인 수정이 완료되었습니다.",
    i50175: "라인 연동",
    i50176: "수정하기",
    i50177: "챗봇",
    i50178: "카카오톡에서 스윙챗으로 챗봇 및 채팅상담 메시지를 주고 받을 수 있어요.",
    i50179: "알림톡",
    i50180: "상담톡",
    i50181: "카카오 알림톡으로 SMS 없이도 오프라인 알림이나 마케팅에 활용할 수 있어요.",
    i50182: "카카오 상담톡을 스윙챗과 연동해서 채팅 상담 메시지를 주고 받을 수 있어요.",
    i50183: "네이버 톡톡 연동을 해제하였습니다.",
    i50184: "이벤트 받을 URL",
    i50185: "한번만 설정하면 카카오톡 챗봇과 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요. 연동 한방에 챗봇과 채팅상담이 모두 이뤄집니다. 아래 가이드를 따라 설정해 주시고, 혹시 어려운 점이 있으면 스윙챗으로 문의해주세요.",
    i50186: "스윙챗은 하나의 카카오톡 챗봇과 1:1로 연동됩니다.",
    i50187: "사진에서 Link URL을 찾을 수 없습니다.",
    i50188: "네이버 톡톡 연동",
    i50189: "를 참고해서 스윙챗의 ",
    i50190: "이벤트 받을 URL을 네이버 톡톡의 Webhook에 등록",
    i50191: "한 뒤 ",
    i50192: "이벤트를 전체 선택으로 변경",
    i50193: "해 주시고 ",
    i50194: "네이버 톡톡 Authorization Key를 아래 빈칸에 입력해주세요.",
    i50195: "이벤트 받을 URL",
    i50196: "취소",
    i50197: "추가로 네이버 톡톡에 Link URL을 설정해서 WebChat에서 \"메신저로 바로가기\" 아이콘을 노출할 수 있습니다.\n지금 설정하지 않아도 언제든 추가로 설정할 수 있습니다.",
    i50198: "를 참고해서 ",
    i50199: "네이버 톡톡 Authorization Key를 아래 빈칸에 입력해주세요.",
    i50200: "올바른 라인 프로필 Link URL을 입력하세요. 예) https://line.me/xxxxxx",
    i50201: "라인 링크를 가져오려면 QR코드 이미지(PNG)를 여기에 업로드해주세요.\n\n[QR코드 다운로드 방법]\n1. 라인 비지니스 개발자 콘솔 접속하기\nhttps://developers.line.biz/console\n2. 연결하고자 하는 Provider 내 Channel 메뉴에 들어가기\n3. Messaging API 텝으로 이동하면 보이는 QR code를 마우스 우클릭 '다른 이름으로 저장'하여 다운로드하기",
    i50202: "http://pf.kakao.com/xxxxxx",
    i50203: "https://m.me/page_name",
    i50204: "https://www.instagram.com/instagram_account",
    i50205: "이미 해당 카카오채널은 다른 채널과 연동되어있습니다",
    i50206: "스윙챗은 하나의 톡톡 계정과 1:1로 연동됩니다.",
    i50207: "한번만 설정하면 네이버 톡톡 채널과 스윙챗이 연동되어 스윙챗에서 채널을 모두 관리할 수 있어요. 연동 한방에 챗봇과 채팅상담이 모두 이뤄집니다. 아래 가이드를 따라 설정해 주시고, 혹시 어려운 점이 있으면 스윙챗으로 문의해주세요.",
    i50208: "시작하기",
    i50209: "상담톡 활성화 대기중",

    i60001: "지식그래프 카테고리,\n또는 자주 묻는 질문에서 원하는 질의를\n선택해 주세요.",
    i60002: "스윙챗 챗봇빌더",
    i60003: "지식그래프",
    i60004: "많이 선택한 질문",
    i60005: "자주 묻는 질문이 비어있습니다.",
    i60006: "스킬이 비어있습니다.",

    i60101: "답변 초기화",
    i60102: "작성 중인 답변 내용을 초기화하시겠습니까?",
    i60103: "스마트 FAQ를 종료하시겠습니까?",
    i60104: "저장하지 않은 내용은 삭제됩니다.",
    i60105: "종료",
    i60106: "취소",
    i60107: "다른 질문으로 이동하시겠습니까?",
    i60108: "저장하지 않은 내용은 삭제됩니다.",
    i60109: "이동",
    i60110: "등록된 응답이 삭제되었습니다.",
    i60111: "인텐트 반영 실패",
    i60112: "취소",
    i60113: "스마트 FAQ 응답을 등록했습니다.",
    i60114: "스마트 FAQ 응답을 등록 혹은 삭제하는 도중 오류가 발생했습니다.",
    i60115: "메세지를 입력해주세요.",
    i60116: "삭제",
    i60117: "등록",
    i60118: "응답 예시",
    i60119: "등록했던 응답",
    i60120: "북마크",
    i60121: "북마크 등록 불가",
    i60122: "저장하지 않은 답변 내용이 있습니다. 북마크 등록 전에 저장해주세요.",
    i60123: "북마크가 등록되었습니다.",
    i60124: "북마크가 등록하는 도중 오류가 발생했습니다.",
    i60125: "북마크 추가하기",
    i60126: "저장한 북마크는 언제든지 다시 불러내서 적용할 수 있습니다.",
    i60127: "타이틀을 입력해주세요",
    i60128: "빈 타이틀은 입력하실 수 없습니다.",
    i60129: "템플릿",
    i60130: "히스토리",
    i60131: "응답 중 검색하기",
    i60132: "EDIT",
    i60133: "북마크 제목을 변경하였습니다.",
    i60134: "북마크 제목을 변경하는 도중 오류가 발생했습니다.",
    i60135: "북마크가 삭제되었습니다.",
    i60136: "북마크를 삭제하는 도중 오류가 발생했습니다.",
    i60137: "템플릿 제목 변경하기",
    i60138: "변경할 템플릿 제목을 입력해주세요",
    i60139: "편집 모드를 통해 언제든지 템플릿 제목을 변경할 수 있습니다.",
    i60140: "변경",
    i60141: "EDIT 버튼을 누르면 편집 모드가 됩니다.\n제목을 클릭해 수정하거나 삭제를 클릭해 템플릿을 지우세요.",
    i60142: "고객의 질문에 챗봇이 자동으로 답변하게 하세요.",
    i60143: "쇼핑몰 운영 시 고객들이 자주 묻는 질문을 준비했습니다. 답변을 작성하시면 챗봇이 자동으로 답변합니다. 상담원 부재중에도 소중한 고객의 목소리를 놓치지 않을 수 있습니다. ",
    i60144: "스윙챗 대화 편집기",
    i60145: "스윙챗은 고객의 대화의도를 파악합니다. 각 질문에 응답을 넣어 고객과 스윙챗의 대화를 추가해보세요.",
    i60146: "질문 카테고리",
    i60147: "가이드 보기",
    i60148: "스윙챗에서 제공하는 응답 예시입니다. 클릭하여 응답 내용을 가져올 수 있습니다.",
    i60149: "중앙 하단에 있는 북마크 버튼을 통해서 언제든지 작성 중인 응답을 저장할 수 있습니다.\n원하는 이름을 붙여서 북마크를 만들어보세요. 만든 북마크를 클릭하여 내용을 가져올 수 있습니다.",
    i60150: "지금까지 작성했던 응답들이 여기에 기록되어 있습니다.\n원하는 기록을 클릭하여 작성했던 응답을 가져올 수 있습니다.",
    i60151: "여러 쇼핑몰에서 가장 많이 선택한 질문 목록입니다.\n원하는 질문을 클릭하여 바로 답변을 작성해보세요.",
    i60152: "수정",
    i60153: "북마크 추가하기",
    i60154: "빈 메세지가 있습니다. 내용을 입력해주세요.",
    i60155: "북마크 제목을 입력해주세요",
    i60156: "{텍스트 없음}",
    i60157: "등록한 응답 내역을 확인하시겠습니까?",
    i60158: "확인",
    i60159: "등록된 응답 모아보기",
    i60160: "현재 등록된 응답이 없습니다.",
    i60161: "서류를 모두 업로드해주세요.",
    i60162: "발신 번호를 입력해주세요.",
    i60163: "새로운 발신 번호 등록",
    i60164: "{number} 번호로 새로 등록하시겠습니까? 제출한 서류와 함께 검수 과정을 거친 뒤 승인 여부가 결정됩니다.",
    i60165: "발신 번호 등록을 요청했습니다.",
    i60166: "발신 번호 등록에 실패했습니다.",
    i60167: "발신 번호 삭제",
    i60168: "현재 등록된 발신 번호를 삭제합니다. 같은 번호를 이용 중인 다른 채널에서도 이 발신 번호를 사용할 수 없게 됩니다. 그래도 삭제하시겠습니까?",
    i60169: "삭제",
    i60170: "발신 번호를 삭제했습니다.",
    i60171: "발신 번호를 삭제하지 못했습니다.",
    i60172: "발신 번호를 활성화했습니다.",
    i60173: "발신 번호를 비활성화했습니다.",
    i60174: "오류가 발생했습니다.",
    i60175: "발신 번호 등록 요청 취소",
    i60176: "발신 번호 등록 요청을 취소하시겠습니까? 취소한 후에는 새로운 발신 번호와 서류를 제출하여 다시 요청할 수 있습니다.",
    i60177: "심사 요청을 취소하였습니다.",
    i60178: "심사 요청을 취소하지 못했습니다.",
    i60179: "현재 발신 번호",
    i60180: "비활성화",
    i60181: "활성화",
    i60182: "등록된 발신 번호는 활성화 또는 비활성화할 수 있습니다. 발신 번호를 비활성화하면 스윙챗 기본 번호로 전송됩니다.\n\n" +
        "새로운 발신 번호를 등록하려면 이미 등록된 번호를 삭제해주세요.",
    i60183: "단, 삭제한 발신 번호가 다른 채널에서도 사용 중이면 해당 채널들에서도 사용할 수 없게 됩니다. ",
    i60184: "삭제한 발신 번호는 되돌릴 수 없으며, 동일한 번호를 사용하고자 하는 경우 심사 서류를 다시 제출해주세요.",
    i60185: "아래는 현재 심사 중인 발신 번호입니다. 심사 요청을 취소하고 다시 신청할 수 있습니다.",
    i60186: "심사 중인 번호",
    i60187: "위 발신 번호 등록 요청은 반려되었습니다. 자세한 내용은 반려 사유를 참고해주세요.",
    i60188: "아래 양식에 맞춰 새로운 발신 번호를 등록하세요.",
    i60189: "발신번호 사전등록제 법 개정에 따라 다음의 서류를 제출해야 합니다.",
    i60190: "1. 발신번호 위임장",
    i60191: "2. 통신가입증명원 (통신사에서 직접 발급)",
    i60192: "3. 제휴 계약서 (계약서 날인 또는 서명 필요)",
    i60193: "위 서류들이 제휴 메시징 기업에 전달/승인 완료된 후 SMS 발송이 가능합니다.",
    i60194: "올바르지 않은 서류를 제출하신 경우 요청이 반려될 수 있습니다.",
    i60195: "발신 번호 위임장",
    i60196: "파일 예시",
    i60197: "통신 가입 증명원",
    i60198: "제휴 계약서",
    i60199: "새로운 발신 번호",
    i60200: "등록 요청",
    i60201: "취소",
    i60202: "반려 사유",
    i60203: "확인",
    i60204: "발신 번호 등록 반려 사유",
    i60205: "고객님께서 요청하신 발신 번호 등록은 다음과 같은 사유로 반려되었습니다. 정확한 발신 번호와 서류를 다시 업로드하여 신청해주시기 바랍니다.",
    i60206: "반려 사유 없음",
    i60207: "미리보기",
    i60208: "챗봇 미리보기",
    i60209: "필수 입력 항목입니다.",
    i60210: "발송 요일을 선택해주세요.",
    i60211: "발송 시간을 선택해주세요.",
    i60212: "주소 자세히 보기",
    i60213: "API 키",
    i60214: "스윙챗에서 제공하는 OpenAPI를 사용하기 위한 채널별 API 키입니다.",
    i60215: "API 시크릿",
    i60216: "스윙챗에서 제공하는 OpenAPI를 사용하기 위한 채널별 API 시크릿입니다. 새로고침 버튼을 통해 재발급 받을 수 있습니다.",
    i60217: "********************************",
    i60218: "API 시크릿 재발급",
    i60219: "API 시크릿을 재발급하면 이전 시크릿은 더 이상 사용할 수 없습니다. 재발급 하시겠습니까?",
    i60220: "재발급",
    i60221: "API 시크릿을 재발급하였습니다.",
    i60222: "API 시크릿을 재발급하는 도중 에러가 발생했습니다.",


    i70001: "배포시 기존에 등록하신 FAQ는 이용하실 수 없습니다.",
    i70002: "배포 하시겠습니까?",
    i70003: "FAQ 배포",
    i70004: "FAQ 배포하기",
    i70005: "챗봇 서버에 FAQ 내용을 적용",
    i70006: "다시 시도",
    i70007: "상담톡 연동이 해제되었습니다.",
    i70008: "접속 IP 제한",
    i70009: "IP 접근 제한 설정을 통해 채널 보안 수준을 높일 수 있습니다.\nIP 접근 제한을 설정하시겠습니까?\n\n※ 목록에 포함되지 않은 IP로 접속 시 즉시 채널 접근이 차단됩니다.",
    i70010: "IP 접근 제한 설정이 저장되었습니다.",
    i70011: "접속할 수 있는 PC의 IP를 설정한 범위 목록으로 제한합니다.",
    i70012: "IP 형식 오류",
    i70013: "추가",
    i70014: "접속 PC 화이트리스트 등록",
    i70015: "접속하신 PC의 인터넷 IP로 해당 채널에 접근할 수 없습니다.",
    i70016: "해당 채널은 등록한 IP 주소로만 접속이 가능하도록 설정된 상태입니다.\n등록되지 않은 IP로는 접근이 불가능하므로 채널 관리자에게 문의하시기 바랍니다.",
    i70017: "※만약 채널 관리자가 접근이 불가한 경우에는 고객센터(swing.chat@42maru.com)로 문의해주세요.\n사유와 담당자명, 연락처를 기재해주시면 확인 후 조치해드리겠습니다.",
    i70018: "현재 접속 IP",
    i70019: "드래그 차단 설정",
    i70020: "설정을 On으로 변경할 시 채널 관리 도구에서 마우스의 드래그 기능이 제한됩니다.",
    i70021: "드래그 차단 설정이 저장되었습니다.",
    i70022: "모바일 환경 접속 제한",
    i70023: "설정을 On으로 변경할 시 모바일 환경에서 접속이 제한됩니다.",
    i70024: "모바일 환경 접속 제한 설정이 저장되었습니다.",
    i70025: "접속하신 환경(모바일)에서는\n해당 채널에 접근할 수 없습니다.",
    i70026: "해당 채널은 모바일 환경 접속 제한이 설정된 상태입니다.\nPC 환경에서 접속 후 사용하시길 바랍니다.",
    i70027: "설명",
    i70028: "허용할 IP 주소",
    i70029: "허용한 IP 주소",
    i70030: "설정 시간",
    i70031: "수정/삭제",
    i70032: "화이트 리스트 추가하기 +",
    i70033: "접속 허용 IP 삭제 하기",
    i70034: "접속 허용 IP를 삭제하시겠습니까?\n\n※ 목록에 포함되지 않은 IP로 접속 시 즉시 채널 접근이 차단됩니다.",
    i70035: "현재 접속 IP가 기존에 등록한 화이트리스트(허용한 IP 주소 목록)에\n포함되어 있지 않습니다. 설정이 즉시 적용되므로 현재 접속 IP를\n화이트리스트에 추가하시겠습니까?",
    i70036: "화이트리스트 추가하고 설정하기",
    i70037: "돌아가기",
    i70038: "이미 목록에 포함된 IP",
    i70039: "해당 IP는 현재 접속중인 IP로 삭제할 수 없음",
    i70040: "멤버 및 권한 관리",
    i70041: "",
    i70042: "사용자의 관리화면 활동이력을 다운로드 받습니다.",
    i70043: "다운로드 사유",
    i70044: "사유를 입력하세요.",
    i70045: "사용자 데이터 조회 및 관리",
    i70046: "멤버 및 권한 관리",
    i70047: "IP 접근 제한을 해제하시겠습니까?\n\n※ 해제 시 모든 IP 에서의 접속이 즉시 허용됩니다."
};

export default language;
