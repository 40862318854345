import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {DomainLinkBase} from "../model/connector";


class ConnectorAccountRepository {

    async read(channelId: string, connectorAccountId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot-connector/accounts/${connectorAccountId}`);
    }

    async createDomainLink(channelId: string, domainLink: DomainLinkBase) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot-connector/domain-link`, domainLink);
    }

    async deleteDomainLink(channelId: string) {
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot-connector/domain-link`);
    }

    async cafe24Boards(channelId: string){
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot-connector/boards/cafe24`)
    }

}

export default new ConnectorAccountRepository();
