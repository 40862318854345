import loadable from "@loadable/component";


// export const mainView = loadable(() => import("./MainView"));
export const channelContextView = loadable(() => import("./ChannelContextView"));
export const channelListView = loadable(() => import("./channel/ChannelListView"));
export const createChannelModal = loadable(() => import("./channel/CreateChannelModal"));
export const chatbotBuilderView = loadable(() => import("./chatbot/ChatbotBuilderView"));
export const chatbotContextView = loadable(() => import("./chatbot/ChatbotContextView"));
export const chatbotConversationView = loadable(() => import("./chatbot/ChatbotConversationView"));
export const chatbotConversationFlowView = loadable(() => import("./chatbot/ChatbotConversationFlowView"));

export const flowListView = loadable(() => import("./flow/FlowListView"));
export const flowEditView = loadable(() => import("./flow/FlowEditView"));

export const settingMainView = loadable(() => import("./setting/SettingMainView"));
export const chatbotSettingView = loadable(() => import("./setting/ChatbotSettingView"));
// export const messageSettingView = loadable(() => import("./setting/MessageSettingView"));
// export const externalServiceSettingView = loadable(() => import("./setting/ExternalServiceSettingView"));

export const commerceRestockView = loadable(() => import("./setting/chatbot/skill/CommerceRestockView"));

export const clientInfoSettingView = loadable(() => import("./setting/ChatUser/UserAttributeSettingView"));
export const liveChatSettingView = loadable(() => import("./setting/livechat/LiveChatSettingView"));
export const liveChatMemberManagementView = loadable(() => import("./setting/livechat/LiveChatMemberManagementView"));
export const memberView = loadable(() => import("./setting/MemberView"));
export const channelBasicInfoView = loadable(() => import("./setting/ChannelBasicInfoView"));

export const userMainView = loadable(() => import("./user/UserMainView"));
export const userListView = loadable(() => import("./user/UserListView"));
export const userDetailView = loadable(() => import("./user/UserDetailView"));

export const messageMainView = loadable(() => import("./message/MessageMainView"));
export const messageListView = loadable(() => import("./message/MessageListView"));
export const messageDraftView = loadable(() => import("./message/MessageDraftView"));
export const messageResultView = loadable(() => import("./message/MessageResultView"));


export const analyticsContextView = loadable(() => import("./analytics/AnalyticsContextView"));
export const chatbotAnalyticsView = loadable(() => import("./analytics/ChatbotAnalyticsView"));
export const liveChatAnalyticsView = loadable(() => import("./analytics/LiveChatAnalyticsView"));

// export const sampleFullSizeContentView = loadable(() => import("./sample/FullSizeContentView"));

export const liveChatContextView = loadable(() => import("./livechat/LiveChatContextView"));

export const loginView = loadable(() => import("./auth/LoginView"));
export const ssoLoginView = loadable(() => import("./auth/SSOLoginView"));
export const signUpView = loadable(() => import("./auth/SignUpView"));
export const signUpResultView = loadable(() => import("./auth/SignUpResultView"));
export const confirmEmailView = loadable(() => import("./auth/ConfirmEmailView"));
export const forgotPasswordView = loadable(() => import("./auth/ForgotPasswordView"));
export const resetPasswordView = loadable(() => import("./auth/ResetPasswordView"));
export const appLoginRedirectView = loadable(() => import("./auth/AppLoginRedirectView"));
export const appLogoutConfirmView = loadable(() => import("./auth/AppLogoutConfirmView"));
export const inviteAgreeView = loadable(() => import("./auth/InviteAgreeView"));
export const accountView = loadable(() => import("./auth/AccountView"));
export const StalePasswordChangeView = loadable(() => import("./auth/StalePasswordChangeView"));
export const authenticationEmail = loadable(() => import("./auth/AuthenticationEmailView"));
export const mfaFactorView = loadable(() => import("./auth/MfaView"));
export const mfaAuthenticationView = loadable(() => import("./auth/MfaAuthenticationView"));

export const subscriptionView = loadable(() => import("./subscription/SubscriptionView"));
export const earlyAccessSubscriptionView = loadable(() => import("./subscription/EarlyAccessSubscriptionView"));
export const subscriptionSettingView = loadable(() => import("./subscription/SubscriptionSettingView"));
export const subscriptionBillingListView = loadable(() => import("./subscription/SubscriptionBillingListView"));
export const paymentSettingView = loadable(() => import("./setting/payment/PaymentSettingView"));
export const paymentCardView = loadable(() => import("./setting/payment/PaymentCardView"));
export const paymentAccountView = loadable(() => import("./setting/payment/PaymentAccountView"));
export const messageAccountView = loadable(() => import("./setting/payment/MessageAccountView"));
export const textMessageHistoryView = loadable(() => import("./setting/payment/TextMessageHistoryView"));
// export const textMessageSettingView = loadable(() => import("./setting/message/TextMessageSettingView"));
// export const tempTextMessageSettingView = loadable(() => import("./setting/message/TempTextMessageSettingView"));
export const senderNumberManageView = loadable(() => import("./setting/message/SenderNumberManageView"));
export const messengerSettingView = loadable(() => import("./setting/message/MessengerSettingView"));
export const kakaoTalkIntegrationView = loadable(() => import("./setting/message/kakaoTalk/KakaoTalkIntegrationView"));
export const alimtalkIntegrationView = loadable(() => import("./setting/message/kakaoTalk/AlimtalkIntegrationView"))
export const sangdamtalkInegrationView = loadable(() => import("./setting/message/kakaoTalk/SangdamtalkIntegrationView"));
export const naverTalkIntegrationView = loadable(() => import("./setting/message/NaverTalkIntegrationView"));
export const facebookIntegrationView = loadable(() => import("./setting/message/FacebookIntegrationView"));
export const instagramIntegrationView = loadable(() => import("./setting/message/InstagramIntegrationView"));
export const lineIntegrationView = loadable(() => import("./setting/message/LineIntegrationView"));
export const securityMainView = loadable(() => import("./setting/security/SecurityMainView"));

export const integrationSettingView = loadable(() => import("./setting/integration/IntegrationSettingView"));
export const cafe24Guide = loadable(() => import("./setting/integration/Cafe24Guide"));
export const makeShopGuide = loadable(() => import("./setting/integration/MakeShopGuide"));

export const roleView = loadable(() => import("./setting/role/RoleView"));
export const outboundTemplateListView = loadable(() => import("./setting/outboundTemplate/OutboundTemplateListView"));
export const channelAlimtalkOutboundTemplateCreateView = loadable(() => import("./setting/outboundTemplate/ChannelAlimtalkOutboundTemplateCreateView"));
export const channelAlimtalkOutboundTemplateEditView = loadable(() => import("./setting/outboundTemplate/ChannelAlimtalkOutboundTemplateEditView"));

export const outboundMainView = loadable(() => import("./outbound/OutboundMainView"));
export const alimtalkOutboundDraftView = loadable(() => import("./outbound/AlimtalkOutboundDraftView"));
export const alimtalkOutboundEditView = loadable(() => import("./outbound/AlimtalkOutboundEditView"));
export const alimtalkOutboundResultView = loadable(() => import("./outbound/AlimtalkOutboundResultView"));