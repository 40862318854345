import { action, makeAutoObservable, observable } from "mobx";
import React, {CSSProperties} from "react";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useIntl } from "react-intl";
import parse from "html-react-parser";

class DialogStore {
    @observable
    isOpen: boolean = false;

    @observable
    title?: string;

    @observable
    content: string | null = null;

    @observable
    contentAlign: 'center'|'left'|'right' = 'center';

    @observable
    variant: DialogVariant = "primary";

    @observable
    customText?: string;

    @observable
    confirmText?: string;

    @observable
    cancelText?: string;

    @observable
    onClickCustom: VoidFunction | null = null;

    @observable
    onConfirm: VoidFunction | null = null;

    @observable
    onCancel: VoidFunction | null = null;

    @observable
    hideConfirm: boolean = false;

    @observable
    style?: CSSProperties;

    closeIntervalId?: any

    constructor() {
        makeAutoObservable(this);
    }

    @action
    open(params: OpenParams) {
        if (this.closeIntervalId) {
            clearTimeout(this.closeIntervalId);
            this.closeIntervalId = undefined;
        }

        setTimeout(() => {
            this.content = params.content;
            this.contentAlign = params.contentAlign || 'center';
            this.onConfirm = params.onConfirm || null;
            this.title = params.title;
            this.variant = params.variant || "primary";
            this.customText = params.customText;
            this.confirmText = params.confirmText;
            this.cancelText = params.cancelText;
            this.onCancel = params.onCancel || null;
            this.onClickCustom = params.onClickCustom || null
            this.hideConfirm = params.hideConfirm || false;
            this.style = params.style
            this.isOpen = true;
        }, 300);
    }

    @action
    close() {
        this.isOpen = false;
        this.onConfirm = null;
        this.onCancel = null;

        // this.content = null;
        // this.onConfirm = null;
        // this.title = null;
        // this.variant = 'primary';
        // this.confirmText = undefined;
        // this.cancelText = undefined;
        // this.onCancel = null;
        // this.hideConfirm = false;

        this.closeIntervalId = setTimeout(() => {
            this.closeIntervalId = undefined;

            this.title = undefined;
            this.content = null;
            this.contentAlign = 'center';
            this.variant = 'primary';
            this.confirmText = undefined;
            this.cancelText = undefined;
            this.hideConfirm = false;
            this.style = undefined;
        }, 250);
    }
}

type DialogVariant = "primary" | "success" | "warning" | "danger";

type OpenParams = {
    content: string;
    contentAlign?: 'center'|'left'|'right';
    onConfirm?: VoidFunction;
    title?: string;
    variant?: DialogVariant;
    customText?: string;
    confirmText?: string;
    cancelText?: string;
    onClickCustom?: VoidFunction;
    onCancel?: VoidFunction;
    hideConfirm?: boolean;
    style?: CSSProperties
};

const context = React.createContext(new DialogStore());
export const useDialog = () => React.useContext(context);

const Dialog: React.FC = observer(() => {
    const dialog = useDialog();
    const intl = useIntl();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    const onClickCustomBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onClickCustom) {
            dialog.onClickCustom();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true} role="dialog" centered>
            <Modal.Body style={dialog.style}>
                {/*<Modal.Title as={"h4"} className={`text-${dialog.variant}`}>{dialog.title || intl.formatMessage({id: 'i00177'})}</Modal.Title>*/}
                <h4 className={`text-${dialog.variant} text-center mt-2`}>{dialog.title || intl.formatMessage({id: 'i00177'})}</h4>
                <div className="m-3">
                    <p style={{whiteSpace: 'pre-wrap', textAlign: dialog.contentAlign}}>{dialog.content && parse(dialog.content)}</p>
                </div>


                <div className="d-flex justify-content-center mt-2 mb-2">
                    <Button variant={"outline-secondary"} className="btn-rounded" onClick={handleClose}>
                        {dialog.cancelText || intl.formatMessage({ id: "i30006" })}
                    </Button>
                    {dialog.customText && (
                        <Button variant={"outline-secondary"} onClick={onClickCustomBtn} className="ml-1">
                            {dialog.customText}
                        </Button>
                    )}
                    <Button
                        className={classNames("btn-rounded ml-1", { "d-none": dialog.hideConfirm })}
                        variant={dialog.variant}
                        onClick={onClickConfirmBtn}
                    >
                        {dialog.confirmText || intl.formatMessage({ id: "i00179" })}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default Dialog;
