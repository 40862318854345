import {AxiosError} from "axios";


export class SwingChatError extends Error {
    constructor(public code: string, message?: string, originError?: Error) {
        super(message);
        Object.setPrototypeOf(this, SwingChatError.prototype);
        this.originError = originError;
    }

    public readonly isSwingChatError = true;
    public readonly originError?: Error = undefined;
}

type ErrorWithMessage = {
    message: string;
};

export const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
    return (
        typeof error === "object" && error !== null && "message" in error && typeof (error as Record<string, unknown>).message === 'string'
    );
}

export const isAxiosError = (error: unknown): error is AxiosError => {
    return (
        typeof error === "object" && error !== null && "isAxiosError" in error && typeof (error as Record<string, unknown>).isAxiosError === 'boolean'
    );
}

export const isSessionExpiredError = (error: unknown): error is AxiosError => {
    return (
        isAxiosError(error)
        && error?.response?.status === 401
        && typeof error?.response.data === "object"
        && error?.response.data !== null
        && error?.response.data.hasOwnProperty('error_message')
        && (error?.response.data as {error_message: string}).error_message === "Session Expired"
        && !error?.request?.responseURL.endsWith('profile')
    )
}

export const isSwingChatError = (error: unknown): error is SwingChatError => {
    return (
        typeof error === "object" && error !== null && "isSwingChatError" in error && typeof (error as Record<string, unknown>).isSwingChatError === 'boolean'
    );
}

export const toSwingChatError = (unknownError: unknown, code?: string): SwingChatError => {
    if (isAxiosError(unknownError)) {
        return new SwingChatError(String(unknownError.response?.status) ?? '500', unknownError.message, unknownError);
    }

    if (isSwingChatError(unknownError)) {
        return unknownError;
    }

    if (isErrorWithMessage(unknownError)) {
        return new SwingChatError(code ?? '500', unknownError.message);
    }

    try {
        return new SwingChatError(code ?? '500', JSON.stringify(unknownError));
    } catch {
        // 순환 참조와 같이 maybeError를 stringify하는 과정에서 발생하는
        // 에러에 대해 fallback을 제공한다
        return new SwingChatError(code ?? '500', String(unknownError));
    }
}
