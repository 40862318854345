import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class PaymentAccountRepository {

    async read(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/payment-account`);
    }
}


export default new PaymentAccountRepository();