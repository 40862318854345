import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class SubscriptionPlanRepository {

    async find() {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/subscription-plans`);
    }
}


export default new SubscriptionPlanRepository();