import {useQuery} from "@tanstack/react-query";
import {Profile} from "../../model/user";
import {SwingChatError, toSwingChatError} from "../../core/error";
import axios from "axios";
import {CHATBOT_CMS_HOST} from "../../core/variables";


const getData = async () => {
    try {
        const {data} = await axios.get(`${CHATBOT_CMS_HOST}/profile`);
        return data;
    } catch (e) {
        throw toSwingChatError(e);
    }
}

const useLoginUser = () => {
    return useQuery<Profile, SwingChatError>(['loginUser'], async () => getData(), {retry: false, suspense: false});
};


export default useLoginUser;
