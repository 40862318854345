import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {Cafe24Board} from "../model/connector";
import ChatbotConnectorRepository from "../repository/ConnectorAccountRepository";


class ConnectorCafe24BoardListStore {
    @observable
    data: Cafe24Board[];

    @observable
    state: StoreState;

    constructor() {
        this.data = []
        this.state = 'none';
        makeObservable(this);
    }

    @action
    async load(channelId: string) {
        this.state = 'pending';
        try {
            const {data} = await ChatbotConnectorRepository.cafe24Boards(channelId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = []
        this.state = 'none';
    }
}

export default ConnectorCafe24BoardListStore;