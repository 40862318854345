import {action, makeObservable, observable} from "mobx";
import {MessageUser} from "../model/message";
import {StoreState} from "../model/common";
import messageRepository from "../repository/MessageRepository";


const DEFAULT_SIZE = 20;

class MessageUserListStore {
    @observable
    data: MessageUser[]

    @observable
    state: StoreState;

    @observable
    nextState: StoreState;

    @observable
    hasNext: boolean;

    query: {offset: number, limit: number};

    constructor() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        this.hasNext = false;
        this.query = {offset: 0, limit: DEFAULT_SIZE};
        makeObservable(this);
    }

    @action
    async load(channelId: string, messageId: string) {
        this.state = 'pending';
        this.query = {
            offset: 0,
            limit: DEFAULT_SIZE
        };

        try {
            const {data} = await messageRepository.findMessageUsers(channelId, messageId, this.query.offset, this.query.limit);
            this.data = data.result;
            this.hasNext = this.data.length === this.query.limit;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async next(channelId: string, messageId: string) {
        this.nextState = 'pending';
        this.query.offset = this.query.offset + this.query.limit;

        try {
            const {data} = await messageRepository.findMessageUsers(channelId, messageId, this.query.offset, this.query.limit);
            this.data = [...this.data, ...data.result];
            this.hasNext = data.result.length === this.query.limit;
            this.nextState = 'done';
        } catch (e) {
            this.nextState = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
        this.nextState = 'none';
        this.hasNext = false;
        this.query = {offset: 0, limit: DEFAULT_SIZE};
    }
}

export default MessageUserListStore;