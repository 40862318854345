import {action, makeObservable, observable} from "mobx";
import moment from "moment";


class I18nStore {
    @observable
    language: string = localStorage.getItem('language') || 'ko';

    constructor() {
        makeObservable(this);
    }

    @action
    setLanguage(language: string) {
        localStorage.setItem('language', language);
        moment.locale(language);
        this.language = language;
    }
}

export const languages: {[key: string]: string} = {
    'ko': '한국어',
    'en': 'English'
};

export default I18nStore;