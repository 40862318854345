import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {AdditionFAQChannel} from "../model/setting"
import AdditionFAQRepository from "../repository/AdditionFAQRepository";


class AdditionFAQChannelStore {
    @observable
    data: AdditionFAQChannel;

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        this.data = {
            channelId: "",
            threshold: 65,
            checked: false
        };
        makeObservable(this);
    }

    @action
    async load(channelId: string) {
        this.state = 'pending';
        try {
            const {data} = await AdditionFAQRepository.findFAQChannel(channelId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = {
            channelId: "",
            threshold: 65,
            checked: false
        };
        this.state = 'none';
    }
}

export default AdditionFAQChannelStore;