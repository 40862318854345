import React from "react";
import styled from "styled-components";
import classNames from "classnames";

const IconButton: React.FC<IconButtonProps> = (props) => {
    const {children, type, onClick, className, tooltip, tooltipId} = props;

    return (
        <IconButtonStyle type={type || 'button'}
                         className={classNames('btn shadow-none', {[className || '']: className})}
                         onClick={onClick}
                         data-for={tooltipId}
                         data-tip={tooltip}>
            {children}
        </IconButtonStyle>
    );
};


const IconButtonStyle = styled.button`
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
`;

type IconButtonProps = {
    type?: 'button'|'submit';
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
    tooltipId?: string;
    tooltip?: string;
};

export default IconButton;


