import {action, computed, makeAutoObservable, observable} from "mobx";
import _ from 'lodash';

import {StoreState} from "../model/common";
import channelClientRepository, {UserJobType} from "../repository/ChannelUserRepository";

export type ClientCount = {
    total: number,
}

export type UserJobStatus = 'pending' | 'done' | 'failed'

class UserJobStore {
    @observable
    data: {[key: string]: UserJobStatus} = {};

    @observable
    state: StoreState = 'none';

    constructor() {
        makeAutoObservable(this)
    }

    @action
    async load(channelId: string, jobType: UserJobType) {
        this.state = 'pending'
        try {
            const {data} = await channelClientRepository.findJobs(channelId, jobType)
            this.data = data.result
            this.state = 'error'
        } catch {
            this.state = 'error'
        }
    }

    @action
    set(data: {[key: string]: UserJobStatus}) {
        if (this.state === 'none') {
            this.state = 'done'
        }
        this.data = data
    }

    @computed
    jobProgress() {
        if (_.isEmpty(this.data)) {
            return 100
        } else {

            let doneJobCount = 0
            for (let jobId in this.data) {
                if (this.data[jobId] !== 'pending') {
                    doneJobCount += 1
                }
            }
            return Math.floor((doneJobCount / Object.keys(this.data).length) * 100)
        }
    }

    @computed
    hasPendingJob() {
        return Object.values(this.data).some(v => v === 'pending')
    }

    @action
    clear() {
        this.data = {}
        this.state = 'none'
    }
}

export default UserJobStore