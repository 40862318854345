import {atom} from "recoil";


const swingChatQnaChatbotState = atom({
    key: 'swingChatQnaChatbot',
    default: {
        show: false
    }
});

export default swingChatQnaChatbotState;
