import {action, makeObservable, observable} from "mobx";
import {PaymentAccount} from "../model/payment";
import {StoreState} from "../model/common";
import paymentAccountRepository from "../repository/PaymentAccountRepository";


class PaymentAccountStore {
    @observable
    data?: PaymentAccount

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    async load(channelId: string) {
        this.state = 'pending';

        try {
            const {data} = await paymentAccountRepository.read(channelId);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}


export default PaymentAccountStore;