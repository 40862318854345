import moment from "moment";
import { action, observable, makeObservable, computed, runInAction } from "mobx";
import { Conversation, MessagingUser, MessageInfo, Page } from "../model/conversationMaker";
import conversationMakerRepository from "../repository/ConversationMakerRepository";
import {StoreState} from "../model/common";

class MessagingUserStore {
    constructor() {
        makeObservable(this);
    }

    @observable selectedConversationId?: string = undefined;
    @observable conversations: Conversation[] = [];
    @observable messages: MessageInfo[] = [];
    @observable isLoadingPreviousMessages: boolean = false;

    @observable
    conversationState: StoreState = 'none';

    conversationPageInfo?: Page<Conversation>;
    messagePageInfo?: Page<MessageInfo> = undefined;
    currentChannelId?: string = undefined;
    focusMessageId?: string;
    lastMessageCreatedAt?: string;

    @action
    setSelectedConversation(conversation?: Conversation) {
        this.selectedConversationId = conversation!.id;
        this.messages = [];
        this.messagePageInfo = undefined;
        this.focusMessageId = undefined;
        this.lastMessageCreatedAt = undefined;
    }

    loadOnlineMessagingUsers(channelId: string, scenarioId: string | undefined) {
        this.currentChannelId = channelId;
        conversationMakerRepository
            .readOnlineMessagingUsers(channelId)
            .then((response) => {
                const conversations = this.conversations.map((conversation) => {
                    return { ...conversation, online: false };
                });

                response.data.result.forEach((onlineMessagingUser: MessagingUser) => {
                    const index = conversations.findIndex((conversation) => {
                        return (
                            onlineMessagingUser.last_conversation &&
                            onlineMessagingUser.last_conversation.id === conversation.id
                        );
                    });
                    if (onlineMessagingUser.last_conversation && index >= 0) {
                        conversations[index] = { ...conversations[index], ...onlineMessagingUser.last_conversation, online: true };
                    } else if (onlineMessagingUser.last_conversation && scenarioId && onlineMessagingUser.last_conversation.scenario_id === scenarioId) {
                        conversations.push({
                            ...onlineMessagingUser.last_conversation,
                            user_key: onlineMessagingUser.user_key,
                            channel: onlineMessagingUser.channel,
                            extra_info: onlineMessagingUser.extra_info,
                            messaging_user_id: onlineMessagingUser.id,
                            online: true
                        });
                    }
                });

                runInAction(() => {
                    if (JSON.stringify(this.conversations) !== JSON.stringify(conversations)) {
                        this.conversations = conversations.sort((lv, rv) => moment(rv.updated_at).diff(moment(lv.updated_at)));
                    }
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    load(channelId: string) {
        this.currentChannelId = channelId;
        conversationMakerRepository
            .readConversations(channelId, undefined, undefined, undefined,
                true, "bot", "bot")
            .then((response) => {
                this.conversationState = 'done'
                runInAction(() => {
                    this.conversations = [...this.conversations, ...response.data.result.content].sort((lv, rv) =>
                        moment(rv.updated_at).diff(moment(lv.updated_at))
                    );
                    this.conversationPageInfo = response.data.result;
                });
            })
            .catch((e) => {
                this.conversationState = 'error'
                console.error(e);
            });
    }

    @action
    loadNext(channelId: string) {
        if (this.conversationPageInfo && this.conversationPageInfo.has_next) {
            this.currentChannelId = channelId;
            conversationMakerRepository
                .readConversations(channelId, this.conversationPageInfo.page + 1, this.conversationPageInfo.size,
                    undefined, true, "bot", "bot")
                .then((response) => {
                    const newConversations: Conversation[] = [];
                    response.data.result.content.forEach((conversation: Conversation) => {
                        const foundItem = this.conversations.find((data) => data.id === conversation.id);

                        if (!foundItem) {
                            newConversations.push(conversation);
                        }
                    });

                    runInAction(() => {
                        this.conversations = [...this.conversations, ...newConversations].sort((lv, rv) =>
                            moment(rv.updated_at).diff(moment(lv.updated_at))
                        );
                        this.conversationPageInfo = response.data.result;
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }

    @action
    loadMessages(channelId: string) {
        this.currentChannelId = channelId;
        if (this.selectedConversationId) {
            conversationMakerRepository
                .readMessagesByConversationId(channelId, this.selectedConversationId, 0, 5)
                .then((response) => {
                    runInAction(() => {
                        this.focusMessageId = response.data.result.content[0].id;
                        this.messages = [...this.messages, ...response.data.result.content].sort((lv, rv) =>
                            moment(lv.created_at).diff(moment(rv.created_at))
                        );

                        this.lastMessageCreatedAt = this.messages[this.messages.length - 1].created_at;
                        this.messagePageInfo = response.data.result;
                    });
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    }

    @action
    loadMessagesSince(channelId: string, callback: () => void) {
        if (this.lastMessageCreatedAt) {
            if (this.selectedConversationId) {
                conversationMakerRepository
                    .readMessagesSinceByConversationId(channelId, this.selectedConversationId, this.lastMessageCreatedAt)
                    .then((response) => {
                        if (response.data.result.length > 0) {
                            const newMessages = response.data.result
                                .map((newMessage: MessageInfo) => {
                                    const foundItem = this.messages.find((message) => {
                                        return newMessage.id === message.id;
                                    });
                                    if (!foundItem) {
                                        return newMessage;
                                    } else {
                                        return undefined;
                                    }
                                })
                                .filter((value: any) => value);

                            runInAction(() => {
                                if (this.focusMessageId !== response.data.result[0].id) {
                                    this.focusMessageId = response.data.result[0].id;
                                }
                                if (this.lastMessageCreatedAt !== response.data.result[0].created_at) {
                                    this.lastMessageCreatedAt = response.data.result[0].created_at;
                                }

                                if (newMessages && newMessages.length > 0) {
                                    this.messages = [...this.messages, ...newMessages].sort((lv, rv) =>
                                        moment(lv.created_at).diff(moment(rv.created_at))
                                    );
                                }
                            });
                        }

                        callback();
                    })
                    .catch((e) => {
                        console.error(e);
                        callback();
                    });
            }
        } else {
            callback();
        }
    }

    @action
    loadNextMessages(channelId: string) {
        this.currentChannelId = channelId;
        if (this.selectedConversationId && this.messagePageInfo && this.messagePageInfo.has_next) {
            this.isLoadingPreviousMessages = true;
            conversationMakerRepository
                .readMessagesByConversationId(
                    channelId,
                    this.selectedConversationId,
                    this.messagePageInfo.page + 1,
                    this.messagePageInfo.size
                )
                .then((response) => {
                    const newMessages = response.data.result.content
                        .map((newMessage: MessageInfo) => {
                            const foundItem = this.messages.find((message) => {
                                return newMessage.id === message.id;
                            });
                            if (!foundItem) {
                                return newMessage;
                            } else {
                                return undefined;
                            }
                        })
                        .filter((value: any) => value);

                    runInAction(() => {
                        this.isLoadingPreviousMessages = false;
                        this.focusMessageId = response.data.result.content[0].id;
                        this.messages = [...this.messages, ...newMessages].sort((lv, rv) =>
                            moment(lv.created_at).diff(moment(rv.created_at))
                        );
                        this.messagePageInfo = response.data.result;
                    });
                })
                .catch((e) => {
                    runInAction(() => {
                        this.isLoadingPreviousMessages = false;
                    });
                    console.error(e);
                });
        }
    }

    @computed
    get selectedMessagingUser() {
        return this.conversations.find((value) => {
            return value.id === this.selectedConversationId;
        });
    }

    @action
    clear() {
        this.conversations = [];
        this.selectedConversationId = undefined;
        this.messages = [];
        this.conversationPageInfo = undefined;
        this.messagePageInfo = undefined;
        this.currentChannelId = undefined;
        this.conversationState = 'none';
    }
}

export default MessagingUserStore;
