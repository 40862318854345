import {action, makeAutoObservable, observable} from "mobx";

import {StoreState} from "../model/common";
import {emptyPage, Page} from "../model/pagination";
import {ChannelUser} from "../model/channelUser";
import channelClientRepository, {PaginationProps} from "../repository/ChannelUserRepository";

export type ClientCount = {
    total: number,
}

class UserStore {
    @observable
    data: Page<ChannelUser>;

    @observable
    count?: ClientCount;

    @observable
    state: StoreState;

    constructor() {
        this.data = emptyPage();
        this.state = 'none';
        makeAutoObservable(this)
    }

    @action
    set(data:Page<ChannelUser>) {
        this.data = data
    }

    @action
    async pagination(props: PaginationProps) {
        this.state = 'pending';
        try {
            const {data} = await channelClientRepository.find({...props});
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e
        }
    }

    @action
    async getClientTotalCount(channelId: string) {
        try {
            const {data} = await channelClientRepository.getClientCount(channelId);
            this.count = data.result
        } catch (e) {
            throw e
        }
    }

    @action
    clear() {
        this.state = 'none';
        this.data = emptyPage()
    }

    @action
    clearCount() {
        this.count = undefined;
    }
}

export default UserStore