import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";

export type CommerceProductFindProp = {
    channelId: string,
    page: number,
    size: number,
    name?: string,
    productInfo: {
        agencyId?: string
        name?: string
    }
}

class CommerceAgencyProductRepository {

    async findAgencyProducts(props: CommerceProductFindProp) {
        const {channelId, ...body} = props;
        return axios.post(
            `${CHATBOT_CMS_HOST}/v1/templates/commerce/channels/${channelId}/agency-products/fetch`,
            body
        );
    }
}

export default new CommerceAgencyProductRepository();