import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import {APP_DESCRIPTION, APP_TITLE, AXIOS_DEFAULTS_TIMEOUT} from "./core/variables";
// import "./assets/css/app.min.css";
// import "./assets/css/icons.css";
import "./assets/global.scss";
// import "./assets/css/bs-custom.scss";
import { RecoilRoot } from "recoil";
import facebookSdkStore from "./store/FacebookSdkStore";
import {Helmet, HelmetProvider } from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {isSessionExpiredError} from "./core/error";

axios.defaults.withCredentials = true;
axios.defaults.timeout = Number(AXIOS_DEFAULTS_TIMEOUT);
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Redirect to login page if login session is expired
        // but session expiration error should not be caught by ErrorBoundary
        if (isSessionExpiredError(error)) {
            window.location.replace('/auth/login?expired=true');
            return new Promise(() => {});
        } else {
            return Promise.reject(error);
        }
    }
);

configure({
    enforceActions: "never"
});

if (process.env.REACT_APP_FACEBOOK_APP_ID && process.env.REACT_APP_FACEBOOK_APP_SECRET) {
    facebookSdkStore.initialize(process.env.REACT_APP_FACEBOOK_APP_ID, process.env.REACT_APP_FACEBOOK_APP_SECRET).catch(error => {
        console.error(error);
    });
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <meta name="description" content={APP_DESCRIPTION} />
                <title>{APP_TITLE} - {APP_DESCRIPTION}</title>
            </Helmet>
            <QueryClientProvider client={queryClient}>
                <RecoilRoot>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </RecoilRoot>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right"/>
            </QueryClientProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
