import React, {useMemo} from "react";
import {AxiosError} from "axios";
import View40X from "./error/View40X";
import {useIntl} from "react-intl";
import View500 from "./error/View500";
import {SwingChatError} from "../core/error";

const ErrorFallback: React.FC<{error: Error, resetErrorBoundary: (...args: Array<unknown>) => void}> = ({error}) => {
    const intl = useIntl();
    // const location = useLocation();

    const axiosError = useMemo(() => {
        if ('isAxiosError' in error) {
            const axiosError = error as AxiosError
            if (axiosError.isAxiosError) {
                return axiosError;
            }
        }

        return null;
    }, [error]);

    const swingChatError = useMemo(() => {
        if ('isSwingChatError' in error) {
            const _swingChatError = error as SwingChatError
            if (_swingChatError.isSwingChatError) {
                return _swingChatError;
            }
        }

        return null;
    }, [error]);

    const code = useMemo(() => {
        if (axiosError) {
            return axiosError.response?.statusText;
        }
        else if (swingChatError) {
            return swingChatError.code;
        }

        return null;
    }, [axiosError, swingChatError]);

    // const onClickReset = (e: React.MouseEvent) => {
    //     e.preventDefault();
    //
    //     resetErrorBoundary();
    // }

    if (code === '403') {
        return (
            <View40X code={code} title={intl.formatMessage({id: 'i00560'})} description={intl.formatMessage({id: 'i00002'})}/>
        );
    }
    else if (code === '404') {
        return (
            <View40X code={code} title={intl.formatMessage({id: 'i00559'})} description={intl.formatMessage({id: 'i00003'})}/>
        );
    }
    else {
        return (
            <View500 />
        );
    }
}

export default ErrorFallback;