import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {ChannelListItem} from "../model/channel"
import channelRepository from "../repository/ChannelRepository"
import settingChatbotRepository from "../repository/ChatbotSettingRepository"

class ChannelListStore {
    size = 20;
    offset = 0;

    @observable
    data: ChannelListItem[];

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        this.data = [];
        makeObservable(this);
    }

    @action
    async load(channelName?: string): Promise<ChannelListItem[]> {
        this.state = 'pending';
        this.offset = 0;
        try {
            const {data} = await channelRepository.find(this.offset, this.size, channelName);
            const result = data.result

            this.data = result;
            this.state = 'done';
            this.offset = data.result.length;
            return result
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async more(channelName?: string): Promise<void> {
        this.state = 'pending';
        try {
            const {data} = await channelRepository.find(this.offset, this.size, channelName);
            this.data = [...this.data].concat(data.result);

            this.state = 'done';
            this.offset += data.result.length;
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    async loadChannelThemes() {

        if (this.data) {
            try {
                const channelIds = this.data.map(v => v._id)

                const {data} = await settingChatbotRepository.findChatbotTheme(channelIds)
                const themes = data.result

                for (let i = 0; i < this.data.length; i ++) {
                    const channel = this.data[i]

                    if (channel._id in themes) {
                        channel.theme = themes[channel._id]
                    }
                }
            } catch (e){
                console.log("Failed to load channel themes")
            }

        }
    }

    @action
    clear() {
        this.offset = 0;
        this.data = [];
        this.state = 'none';
    }
}

export default ChannelListStore;
