import React from "react";
import {Route, RouteProps} from "react-router-dom";
import {Redirect, useLocation} from "react-router";
import {WEB_HOST} from "../core/variables";
import useLoginUser from "../query/loginUser/useLoginUser";

const PrivateRoute: React.FC<RouteProps> = ({children, ...routeProps}) => {
    const location = useLocation();
    const {data: profile, error} = useLoginUser();

    if (profile) {
        return (
            <Route {...routeProps}>
                {children}
            </Route>
        );
    }
    else {
        if (error?.code === '401') {
            return (
                <Redirect to={`/auth/login?client_redirect_uri=${WEB_HOST}${location.pathname}${location.search}`} />
            );
        }
        else {
            return (
                <div>Error!</div>
            );
        }
    }

};

export default PrivateRoute;