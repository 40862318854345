import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import swingChatQnaChatbotAtom from "../recoil/swingChatQnaChatbot";
import classNames from "classnames";
import {QNA_CHATBOT_URL} from "../core/variables";

const SwingChatQnaChatbot: React.FC = () => {

    const [swingChatQnaChatbot, setSwingChatQnaChatbot] = useRecoilState(swingChatQnaChatbotAtom);
    const [loaded, setLoaded] = useState(false);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const loadSwingChatQnaChatbotIframe = (e: any) => {
        e.preventDefault();

        setLoaded((prev) => {
            return !prev;
        });
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        iframe?.addEventListener('load', loadSwingChatQnaChatbotIframe);

        return () => {
            iframe?.removeEventListener('load', loadSwingChatQnaChatbotIframe);
        }
    }, [swingChatQnaChatbot.show]);

    useEffect(() => {
        if (!loaded) {
            setSwingChatQnaChatbot({show: false});
            iframeRef.current?.setAttribute('src', QNA_CHATBOT_URL);
        }
    }, [loaded, setSwingChatQnaChatbot]);

    return (
        <QnaSwingChatChatbotStyle className={classNames({'d-none': !swingChatQnaChatbot.show})}>
            <iframe id="swing-chat-qna-iframe"
                    title="swing.chat QNA"
                    src={QNA_CHATBOT_URL}
                    width="100%"
                    height="100%"
                    // frameBorder="0"
                    style={{border: 'none', borderRadius: '8px'}}
                    ref={iframeRef} />
        </QnaSwingChatChatbotStyle>
    );
};

const QnaSwingChatChatbotStyle = styled.div`
  position: fixed;
  bottom: 30px;
  right: 25px;
  width: 370px;
  height: 710px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 12px 60px 5px !important;
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 99999;
`;

export default SwingChatQnaChatbot;
