import { action, observable, makeObservable } from "mobx";
import conversationMakerRepository from "../repository/ConversationMakerRepository";
import { SankeyNodes, SankeyLink } from "../component/ReactSankey/ReactSankey";
import moment from "moment";
import "moment/locale/ko";
import {StoreState} from "../model/common";

moment.locale("ko");

interface SankeyData {
    scene_id: number;
    scene_name: string;
    level: number;
    value: number;
    previous_scene_ids: number[];
}

interface QueryRankingItem {
    _id: string;
    message_count: number;
}

interface SceneRankingItem {
    _id: string;
    count: number;
}

interface Ranking<T> {
    total: number;
    list: T[];
}

const createNode = (title: string, value: number, id: string) => ({
    title,
    value,
    id,
    children: []
});
const createLink = (sourceId: string, targetId: string) => ({
    sourceId,
    targetId
});

export const getDefaultStartDate = () => {
    return moment().subtract(7, "d").startOf("day").toDate();
};

export const getDefaultEndDate = () => {
    return moment().toDate();
};

class ConversationFlowStore {
    constructor() {
        makeObservable(this);
    }

    @observable sankeyNodes: SankeyNodes = {};
    @observable sankeyLinks: SankeyLink[] = [];
    @observable sankeyRootId: string = "1_1";
    @observable sankeyMaxLevel: number = 0;
    @observable startDate: Date = getDefaultStartDate();
    @observable endDate: Date = getDefaultEndDate();
    @observable sceneRankings?: Ranking<SceneRankingItem>;
    @observable queryRankings?: Ranking<QueryRankingItem>;
    @observable states: {[key: string]: StoreState} = {chatFlow: 'none', sceneRanking: 'none', queryRanking: 'none'}
    currentChannelId?: string = undefined;

    @action
    loadChatFlow = async (channelId: string, startDate: Date, endDate: Date) => {
        this.currentChannelId = channelId;
        this.states = {...this.states, chatFlow: 'pending'}
        try {
            const response = await conversationMakerRepository.getConversationFlow(channelId, startDate, endDate);
            const responseData = response.data.result || {};
            const nodes: SankeyNodes = {};
            const links: SankeyLink[] = [];
            let sankeyData = responseData.filter((data: SankeyData) => {
                return data.level <= 20;
            });

            this.sankeyMaxLevel = (sankeyData.length > 0 && sankeyData[sankeyData.length - 1].level) || 0;

            const levelOneSankeyData = sankeyData.filter((data: SankeyData) => data.level === 1);
            let entryValue = 0;

            sankeyData = sankeyData.map((data: SankeyData) => {
                if (data.level === 1) {
                    entryValue = entryValue + data.value;
                    return { ...data, previous_scene_ids: [0] };
                }

                return data;
            });
            if (levelOneSankeyData.length > 1) {
                this.sankeyRootId = "0_0";
                (sankeyData as SankeyData[]).unshift({
                    level: 0,
                    scene_id: 0,
                    scene_name: "Entry",
                    previous_scene_ids: [0],
                    value: entryValue
                });
            } else {
                this.sankeyRootId =
                    (sankeyData[0] && sankeyData[0].scene_id && `${sankeyData[0].level}_${sankeyData[0].scene_id}`) || "1_1";
            }

            sankeyData.forEach((data: SankeyData) => {
                const id = `${data.level}_${data.scene_id}`;
                nodes[id] = createNode(data.scene_name, data.value, id);
                data.previous_scene_ids.forEach((previous_scene_id: number) => {
                    if (previous_scene_id >= 0) {
                        const sourceId = `${data.level - 1}_${previous_scene_id}`;
                        const found = links.find((link) => link.sourceId === sourceId && link.targetId === id);
                        const existNode = nodes[sourceId];

                        if (!found && existNode) {
                            links.push(createLink(sourceId, id));
                        }

                        // if (!existNode) {
                        //     delete nodes[id];
                        // }
                    }
                });
            });
            this.sankeyNodes = nodes;
            this.sankeyLinks = links;

            this.states = {...this.states, chatFlow: 'done'}
            return Promise.resolve(response.data);
        } catch (e) {
            this.states = {...this.states, chatFlow: 'error'}
            return Promise.reject(e);
        }
    };

    @action
    loadQueryRankings = async (channelId: string, startDate: Date, endDate: Date) => {
        this.currentChannelId = channelId;
        this.states = {...this.states, queryRanking: 'pending'}
        try {
            const response = await conversationMakerRepository.getQueryRankings(channelId, startDate, endDate);
            this.queryRankings = response.data.result;

            this.states = {...this.states, queryRanking: 'done'}
            return Promise.resolve(response.data);
        } catch (e) {
            this.states = {...this.states, queryRanking: 'error'}
            return Promise.reject(e);
        }
    };

    @action
    loadSceneRankings = async (channelId: string, startDate: Date, endDate: Date) => {
        this.currentChannelId = channelId;
        this.states = {...this.states, sceneRanking: 'pending'}
        try {
            const response = await conversationMakerRepository.getSceneRankings(channelId, startDate, endDate);
            this.sceneRankings = response.data.result;

            this.states = {...this.states, sceneRanking: 'done'}
            return Promise.resolve(response.data.result);
        } catch (e) {
            this.states = {...this.states, sceneRanking: 'error'}
            return Promise.reject(e);
        }
    };

    @action
    setDate = (startDate?: Date, endDate?: Date) => {
        this.startDate = startDate!;
        this.endDate = endDate!;

        if (this.currentChannelId) {
            this.loadChatFlow(this.currentChannelId, startDate || getDefaultStartDate(), endDate || getDefaultEndDate());
            this.loadSceneRankings(this.currentChannelId, startDate || getDefaultStartDate(), endDate || getDefaultEndDate());
            this.loadQueryRankings(this.currentChannelId, startDate || getDefaultStartDate(), endDate || getDefaultEndDate());
        }
    };

    @action
    clear() {
        this.sankeyNodes = {};
        this.sankeyLinks = [];
        this.sankeyRootId = "1_1";
        this.sankeyMaxLevel = 0;

        this.states = {chatFlow: 'none', sceneRanking: 'none', queryRanking: 'none'}
    }
}

export default ConversationFlowStore;
