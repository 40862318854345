import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {ChatbotBanner, ChatbotTheme} from "../model/setting"
import {Content, FileInfo} from "@42maru/content-editor";

class ChatbotSettingRepository {

    async read(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot`);
    }

    async updateChatbotName(channelId: string, chatbotName: string, chatbotSubName: string, chatbotWelcome: Content[], chatbotWelcomeFiles: FileInfo[]) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/name`, {
            chatbotName, chatbotSubName, chatbotWelcome, chatbotWelcomeFiles
        });
    }

    async findChatbotTheme(channelIds: string[]) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/chatbot/themes`, {params: {channelIds: channelIds.join(',')}});
    }

    async updateChatbotTheme(channelId: string, chatbotTheme: ChatbotTheme) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/theme`, chatbotTheme);
    }

    async updateChatbotBanner(channelId: string, chatbotBanner: ChatbotBanner) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/banner`, chatbotBanner);
    }

    async uploadAvatarFile(channelId: string, file: any) {
        let form = new FormData();
        form.append("file", file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/avatar`, form);
    }

    async deleteAvatarFile(channelId: string){
        return axios.delete(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/avatar`);
    }

    async uploadDataFile(channelId: string, file: any){
        let form = new FormData();
        form.append("file", file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/data`, form);
    }
}

export default new ChatbotSettingRepository();
