
export const WEB_HOST = process.env.REACT_APP_WEB_HOST;
export const CHATBOT_CMS_HOST = process.env.REACT_APP_CHATBOT_CMS_HOST;
export const KEEPER_API_HOST = process.env.REACT_APP_KEEPER_API_HOST;
export const KEEPER_CLIENT_ID = process.env.REACT_APP_KEEPER_CLIENT_ID;
export const AXIOS_DEFAULTS_TIMEOUT = process.env.REACT_AXIOS_DEFAULTS_TIMEOUT;
export const WEBCHAT_HOST = process.env.REACT_APP_WEBCHAT_HOST;
export const LIVECHAT_WEB_HOST = process.env.REACT_APP_LIVE_CHAT_WEB_HOST as string;
export const LIVECHAT_DEEP_LINK = process.env.REACT_APP_LIVE_CHAT_DEEP_LINK as string;
export const LIVECHAT_MAC_DOWNLOAD_LINK = process.env.REACT_APP_LIVE_CHAT_DOWNLOAD_MAC as string;
export const LIVECHAT_WIN_DOWNLOAD_LINK = process.env.REACT_APP_LIVE_CHAT_DOWNLOAD_WIN as string;
export const CM_API_HOST = process.env.REACT_APP_CM_API_HOST;
export const CONSOLE_WEBSOCKET_HOST = process.env.REACT_APP_CONSOLE_WEBSOCKET_HOST as string;
export const CONSOLE_WEBSOCKET_PATH = process.env.REACT_APP_CONSOLE_WEBSOCKET_PATH ?? '/socket.io';
export const WEBCHAT_WEBSOCKET_HOST = process.env.REACT_APP_WEBCHAT_WEBSOCKET_HOST;
export const WEBCHAT_WEBSOCKET_PATH = process.env.REACT_APP_WEBCHAT_WEBSOCKET_PATH ?? '/socket.io';
export const WEBCHAT_LANDING_HOST = process.env.REACT_APP_WEBCHAT_LANDING_HOST;
export const CHATBOT_CMS_API = process.env.REACT_APP_CHATBOT_CMS_API;
export const APP_TITLE = process.env.REACT_APP_TITLE;
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
export const WEBCHAT_SCRIPT_HOST = process.env.REACT_APP_WEBCHAT_SCRIPT_HOST;
export const REGEX_URL = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
export const QNA_CHATBOT_URL = process.env.REACT_APP_QNA_CHATBOT_URL as string;