export type Page<T> = {
    contents: T[];
    page: number;
    size: number;
    total: number;
    totalPage: number;
};

export const emptyPage = () => {
    return {contents: [], page: 1, size: 20, total: 0, totalPage: 0};
};

export type PaginationProps<T> = {
    filters: T
    offset: number
    limit: number
}

// For infinite query supported by react-query library
export type PageWithParams<T, E> = {
    page: T[]
    pageParams: {
        filters: E
        offset: number
        limit: number
        isLast: boolean
    }
}

export const PAGE_COUNT_LIMIT = 15;
