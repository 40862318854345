import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class SubscriptionBillingRepository {

    async find(channelId: string, offset?: number, limit?: number) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/subscription/billings`, {
            params: {
                offset: offset || 0,
                limit: limit || 20
            }
        });
    }

}


export default new SubscriptionBillingRepository();