import React, {useEffect} from "react";
import useLoginUser from "../../query/loginUser/useLoginUser";
import {useSetRecoilState} from "recoil";
import profileState from "../../recoil/profile";


const AuthProvider: React.FC = ({children}) => {
    const {data, isLoading} = useLoginUser();
    const setProfile = useSetRecoilState(profileState);


    useEffect(() => {
        setProfile(data);
    }, [data, setProfile]);

    if (isLoading) {
        return null;
    }
    else {
        return (
            <>
                {children}
            </>
        );
    }
}

export default AuthProvider;